import React, { useEffect } from "react";
import Banner from "../../components/banner/Banner";
import AboutImg from "../../assets/img/login.jpeg";
import "./About.scss";
import SubHeading from "../../components/heading/SubHeading";
import Loader from "../../components/Loader/Loader";
import { useSelector, useDispatch } from "react-redux";
import { getAboutContent } from "../../redux/slices/about.slice";
import BASE_URL from "../../utils/baseUrl";

const About = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAboutContent());
  }, []);
  const about = useSelector((state) => state.about);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  
  return (
    <div className="about-container">
      {about.status === "SUCCESS" ? (
        <>
          <Banner
            title="About Us"
            subtitle="Want to know about Us"
            image={about?.data[0].aboutBanner}
          />
          <div className="about-body">
            {/* <div className="about-heading">
          <p>
            <span className="subtitle">Who are we</span>
          </p>
          <h2 className="title">About Us</h2>
        </div> */}
            <SubHeading title="About Us" subtitle="Who are we" />

            <div className="detail">
              <div className="about-detail">
                <div class="imagebox">
                  <img
                    src={`${BASE_URL}${about?.data[0].aboutImage}`}
                    width="100%"
                    height="auto"
                  />
                </div>
                <div class="textbox">
                  <p>
                    {" "}
                    {about?.data[0]?.aboutDesc1}
                    {/* It is the ultimate decorating and design guide, as well as the
                go-to resource for anyone looking to live a more beautiful life.
                Novo Creation honor to create the most desirable rugs from our
                in- house studio to the visionary designers with whom we engage
                have blazed a road in their respective of fashion & design. Novo
                Creation has strived to manufacture the most coveted rugs in the
                world since its inception. We accomplish this in a unique way by
                merging the traditional art of rug production with the creative
                creativity of today's most gifted designers. Our rugs are
                stunning, unique, and one-of-a-kind. Whether your style is
                classic or contemporary—or a mix of the two—you'll discover
                ideas and inspiration, as well as the most up-to-date
                information and products and tools to help you decorate,
                renovate, and entertain in style. Each rug takes three months to
                make; yet they will last generations since we use the same
                time-tested techniques that have been used by artisans for
                centuries. 
                Our rugs' subtle textures are amplified by the raw
                materials we select, creating a combination of nature and
                artistry. We only utilize wool from the Tibetan plateau, wool
                that is so high in nature. Tibetan carpets are handcrafted rugs
                that are gorgeous and genuine to the craftsmanship of the
                weavers. They have a few tools and a pre-designed blueprint in
                front of them, on which they work by hand for days to
                manufacture high-quality, long-lasting rugs in Nepal. We
                recognize that choosing a rug is a process as well — a personal
                trip that takes time but is well worth the effort. We provide
                our services with the goal of satisfying our customers' needs
                and requirements, and we aim to provide high quality standards
                with some added benefits that can help them place their own
                custom orders, which we, on the other hand, can weave and
                deliver to their doorsteps. We are proud of our ability to bring
                art, design, and architecture together. Novo Creation
                accomplishes this by emphasizing the importance of rugs in home
                design. 
                As a result, we strive for outstanding quality while
                maintaining the greatest levels of customer service. As a
                result, each handmade work is completely unique. We are design
                lovers who are constantly seeking to innovate and inspire. We
                recognize the value of excellent customer service, and our sales
                staff is knowledgeable not just about the carpets but also about
                how to effectively utilize and arrange them. They will go to
                great efforts to help you navigate the rug purchase process and
                select the perfect piece. We don't use any child labor in any of
                our production sites, and we treat our valued craftspeople like
                family. Our commitment to the environment is shown in the fact
                that all Novo Creation rugs are produced entirely of natural
                materials. Our rugs are handcrafted without the use of any
                machinery. As a result, our rugs meet the most demanding
                environmental requirements. */}
                  </p>
                  <p>{about?.data[0]?.aboutDesc2}</p>
                  <p>{about?.data[0]?.aboutDesc3}</p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default About;
