import { configureStore, combineReducers } from "@reduxjs/toolkit";
import snackbarSlice from "./slices/snackbar.slice";
import userSlice from "./slices/user.slice";
import categorySlice from "./slices/category.slice";
import rugsSlice from "./slices/rugs.slice";
import favouriteSlice from "./slices/favourite.slice";
import aboutSlice from "./slices/about.slice";
import contactSlice from "./slices/contact.slice";
import designSlice from "./slices/design.slice";
import searchBannerSlice from "./slices/searchBanner.slice";
import favBannerSlice from "./slices/favBanner.slice";
import registerImageSlice from "./slices/registerImage.slice";
import loginImageSlice from "./slices/loginImage.slice";
import homeSlice from "./slices/home.slice";
import mailSlice from "./slices/mail.slice";
import adminSlice from "./slices/admin.slice";

const reducer = combineReducers({
  snackbar: snackbarSlice,
  user: userSlice,
  category: categorySlice,
  rugs: rugsSlice,
  admin: adminSlice,
  fav: favouriteSlice,
  about: aboutSlice,
  contact: contactSlice,
  design: designSlice,
  searchBanner: searchBannerSlice,
  favBanner: favBannerSlice,
  registerImage: registerImageSlice,
  loginImage: loginImageSlice,
  home: homeSlice,
  mail: mailSlice,
});

const store = configureStore({
  reducer,
});

export default store;
