import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "../add.module.css";
import { FiCamera } from "react-icons/fi";
import {
  AiOutlineMinus,
  AiFillCloseCircle,
  AiOutlineLoading3Quarters,
} from "react-icons/ai";
import { BiPlus } from "react-icons/bi";
// import { GurkhaContext } from "../../../App";
import axios from "axios";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import BASE_URL from "../../../utils/baseUrl";
import { useSelector } from "react-redux";
function AddCategory({ edit }) {
  //   const { partner } = useContext(GurkhaContext);

  const navigate = useNavigate();
  const categories = useSelector((state) => state.category);

  if (!categories?.data || categories?.data.length === 0) {
    navigate("/");
  }
  const params = useParams();

  if (params.id) {
    console.log(params.id);
  }
  const curCategory = categories?.data.find((el) => el._id === params.id);
  console.log(curCategory);
  const user = useSelector((state) => state.user);
  useEffect(() => {
    user.permissionLevel === 0 && navigate("/", { replace: true });
  }, [user, navigate]);

  const [coverImage, setCoverImage] = useState(null);
  const [logoImage, setLogoImage] = useState(null);
  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [category, setCategory] = useState(edit ? curCategory?.category : "");

  //   useEffect(() => {
  //     if (params.id) {
  //       let active_partner = partner?.find((el) => el._id === params.id);

  //       setPartnerDetails({
  //         title: active_partner?.title || "",
  //         short_des: active_partner?.short_des || "",
  //         long_des: active_partner?.long_des || "",
  //         location: active_partner?.location || "",
  //         service_title: active_partner?.service_title || "",
  //         service_des: active_partner?.service_des || "",
  //       });

  //       setCoverImage(
  //         `${process.env.REACT_APP_SERVER_DOMAIN}${active_partner?.image}`
  //       );

  //       setLogoImage(
  //         `${process.env.REACT_APP_SERVER_DOMAIN}${active_partner?.logo}`
  //       );

  //       setTags([...active_partner?.tags]);

  //       //   let gallery_images = active_partner?.gallery?.map((image) => {
  //       //     return `${process.env.REACT_APP_SERVER_DOMAIN}${image}`;
  //       //   });

  //       //   setGallery(gallery_images);
  //       //   setGalleryFiles(active_partner?.gallery);
  //     }
  //   }, [params, partner]);

  const handleBannerImageChange = (e) => {
    if (e.target.files[0]) {
      setCoverImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleLogoImageChange = (e) => {
    if (e.target.files[0]) {
      setLogoImage(URL.createObjectURL(e.target.files[0]));
    }
  };
  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(URL.createObjectURL(e.target.files[0]));
    }
  };
  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);

    let data = new FormData();

    data.append("category", category);
    data.append("slug", category.toLowerCase().replaceAll(" ", "_"));

    data.append("banner", e.target.banner?.files[0] || null);
    data.append("logo", e.target.logo?.files[0] || null);
    data.append("image", e.target.image?.files[0] || null);

    if (edit) {
      axios
        .put(`${BASE_URL}/api/v1/categories/${params.id}`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("gurkha-token")}`,
          },
        })
        .then((res) => {
          setIsLoading(false);
          navigate("/", { replace: true });
          toast.success("Successfully edited.Please refresh the page");
        })
        .catch((err) => {
          console.log(err);
          toast.error("Sorry there occured a problem");
          setIsLoading(false);
        });
    } else {
      axios
        .post(`${BASE_URL}/api/v1/category`, data, {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        })
        .then((res) => {
          toast.success("Successfully added.");
          navigate("/", { replace: true });
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Sorry there occured a problem");
          setIsLoading(false);
        });
    }
  };

  return (
    <form
      className={styles.form}
      onSubmit={handleFormSubmit}
      disabled={isLoading}
    >
      <div className={styles.container}>
        <h1>{edit ? "Edit" : "Add"} Category</h1>

        <div className={styles.row}>
          <div className={`${styles.inputs} ${styles.imageInput}`}>
            <label htmlFor="image">Banner Image</label>
            <div style={{ backgroundImage: `url("${coverImage}")` }}>
              {edit ? (
                <input
                  type="file"
                  name="banner"
                  accept=".jpg, .jpeg, .png, .heic, .HEIC"
                  onChange={handleBannerImageChange}
                />
              ) : (
                <input
                  type="file"
                  name="banner"
                  accept=".jpg, .jpeg, .png, .heic, .HEIC"
                  onChange={handleBannerImageChange}
                  required
                />
              )}
              <span>
                <FiCamera />
                <p>Add Image</p>
              </span>
            </div>
          </div>
          <div className={`${styles.inputs} ${styles.imageInput}`}>
            <label htmlFor="logo">Logo</label>
            <div style={{ backgroundImage: `url("${logoImage}")` }}>
              {edit ? (
                <input
                  type="file"
                  name="logo"
                  accept=".jpg, .jpeg, .png, .heic, .HEIC"
                  onChange={handleLogoImageChange}
                />
              ) : (
                <input
                  type="file"
                  name="logo"
                  accept=".jpg, .jpeg, .png, .heic, .HEIC"
                  onChange={handleLogoImageChange}
                  required
                />
              )}
              <span>
                <FiCamera />
                <p>Add Image</p>
              </span>
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={`${styles.inputs} ${styles.imageInput}`}>
            <label htmlFor="logo">Main image</label>
            <div style={{ backgroundImage: `url("${image}")` }}>
              {edit ? (
                <input
                  type="file"
                  name="image"
                  accept=".jpg, .jpeg, .png, .heic, .HEIC"
                  onChange={handleImageChange}
                />
              ) : (
                <input
                  type="file"
                  name="image"
                  accept=".jpg, .jpeg, .png, .heic, .HEIC"
                  onChange={handleImageChange}
                  required
                />
              )}
              <span>
                <FiCamera />
                <p>Add Image</p>
              </span>
            </div>
          </div>
          <div className={styles.inputs}>
            <label htmlFor="title">Title</label>
            <input
              type="text"
              name="title"
              placeholder="Category's Title"
              value={category}
              onChange={handleCategoryChange}
            />
          </div>
        </div>

        <div className={styles.row}></div>

        <button disabled={isLoading}>
          {isLoading ? <AiOutlineLoading3Quarters /> : "Submit"}
        </button>
      </div>
    </form>
  );
}

export default AddCategory;
