import React, { useEffect } from "react";
import "./Fav.scss";
import { Link } from "react-router-dom";
import Banner from "../../components/banner/Banner";
import { useDispatch, useSelector } from "react-redux";
import { getFav } from "../../redux/slices/favourite.slice";
import Loader from "../../components/Loader/Loader";
import { getFavBanner } from "../../redux/slices/favBanner.slice";

const Fav = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFav());
    dispatch(getFavBanner());
  }, [dispatch]);
  const fav = useSelector((state) => state.fav);
  const favState = useSelector((state) => state.favBanner);

  return (
    <div className="fav-container">
      {favState?.status === "SUCCESS" ? (
        <Banner
          title="My Favorites"
          subtitle="Collection of My Fav Rug Design"
          image={favState?.data[0]?.favBanner}
        />
      ) : (
        <Loader />
      )}
      {fav.status === "SUCCESS" ? (
        <div>
          {fav?.data[0]?.favorites?.length > 0 ? (
            <div className="fav-body">
              <div className="rug-improvised-detail">
                {fav.data[0].favorites.map((favList) => (
                  <Link to={`/rug/${favList.rug.slug}`}>
                    <div className="card">
                      <img src={favList.rug.designs[0].image} />
                      <div className="rugName">
                        <h3>{favList.rug.rugName}</h3>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          ) : (
            <div className="no-fav">
              <h2>No favorite items found.</h2>
              <Link to="/designs">
                <button>Add To Favorite Now</button>
              </Link>
            </div>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Fav;
