import React, { useState, useEffect } from "react";
import "./rugdesigns.scss";
import Banner from "../../components/banner/Banner";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../../components/Loader/Loader";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import SubHeading from "../../components/heading/SubHeading";
import Foral from "../../assets/img/Foral.png";
import Geo from "../../assets/img/Geometry.png";
import Ocean from "../../assets/img/Ocean.png";
import { getDesignImages } from "../../redux/slices/design.slice";
import { getRugs, setRugsData } from "../../redux/slices/rugs.slice";
import InfiniteScroll from "react-infinite-scroll-component";
import { publicFetch } from "../../utils/fetch";
import { setSnackbar } from "../../redux/slices/snackbar.slice";
import ConfirmDialog from "../Admin/ConfirmDialog/ConfirmDialog";
const RugDesigns = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  console.log(user, "user");

  const [categoryType, setCategoryType] = useState("");
  const [catId, setCatId] = useState("");
  const [rugs, setRugs] = useState([]);
  const [getStatus, setGetStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [items, setItems] = useState(0);
  const [categoryId, setCategoryId] = useState("");
  const navigate = useNavigate();
  const [dialog, setDialog] = useState({
    isOpen: false,
    _id: "",
  });

  const params = useParams();
  useEffect(() => {
    setCategoryType(params.slug);
  }, []);

  useEffect(() => {
    dispatch(getDesignImages());
    dispatch(getRugs());
  }, [dispatch]);
  const designImage = useSelector((state) => state.design);
  const categories = useSelector((state) => state.category);
  // const rugs = useSelector((state) => state.rugs);
  const categoryName = categories.data.filter((cat) => {
    return categoryType === "all" ? true : cat.slug === categoryType;
  });
  useEffect(() => {
    if (categoryName.length > 0) {
      setLoading(false);
      setCategoryId(categoryName[0]?._id);
    }
  }, [categoryName]);

  const getAllRugs = async () => {
    try {
      const limit = items + 20;
      setItems(limit);
      const page = 1;
      // const privateFetch = publicFetch(getState().user.token);
      const response = await publicFetch.get(
        // `/api/v1/rugs?limit=${limit}&page=${page}`
        categoryType === "all"
          ? `/api/v1/rugs?limit=${limit}&sortby=rugName&order=1&isall=1`
          : `/api/v1/rugs?limit=${limit}&sortby=rugName&order=1&categoryID=${categoryId}`

        // `/api/v1/rugs?status=$limit=${limit}&{status}&limit=${limit}&page=${page}`
      );
      dispatch(
        setRugsData({
          result: response.data,
        })
      );
      setRugs(response.data);
    } catch (error) {
      setGetStatus("FAILED");

      // const responseMessage = error.response.data.message;
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "error",
        })
      );
    }
  };

  // setLoading(false);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (categoryId) {
      getAllRugs();
    }
  }, [categoryId]);

  const fetchMoreData = () => {
    setTimeout(() => {
      if (items >= rugs.total) {
        setHasMore(false);
      } else {
        getAllRugs();
      }
    }, 1500);
  };

  return (
    <div className="rug-design-container">
      {rugs.success == true && designImage.status === "SUCCESS" ? (
        <>
          {params.slug === "all" ? (
            <Banner
              title={`All Collections`}
              subtitle="All of the major collections"
              image={`${designImage?.data[0]?.allCatBanner}`}
            />
          ) : (
            <Banner
              slug={categoryName[0]?.slug}
              title={`${categoryName[0]?.category} Collections`}
              logo={`${categoryName[0]?.logo}`}
              banner={`${categoryName[0]?.banner}`}
              rugDesign={true}
              subtitle={
                params.slug === "abstract"
                  ? "Statements of art that are imaginative"
                  : params.slug === "geometrics"
                  ? "Lines and curves that are incredibly unique"
                  : params.slug === "ocean"
                  ? "Floor art with a sea theme"
                  : params.slug === "transitional"
                  ? "A natural spin on classic design"
                  : params.slug === "artwork"
                  ? "Nature's forms, interpreted in a unique way"
                  : params.slug === "floral"
                  ? "Exotic concepts for your tropical retreat"
                  : params.slug === "reserve"
                  ? "Other exotic concepts"
                  : "All of the major collections"
              }
            />
          )}
          {/* <Banner
            title={`${categoryName[0]?.category} Designs`}
            subtitle="Find various designed rugs for your home or office"
          /> */}
          {params.slug !== "all" && user.permissionLevel === 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "12px",
              }}
            >
              <button
                className="btnLogin"
                onClick={() => {
                  navigate(`/admin/add-rug/${categoryId}`);
                }}
              >
                Add Rug
              </button>
            </div>
          )}
          <div className="rug-design-body">
            <InfiniteScroll
              dataLength={items}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={
                <>
                  <Loader />
                </>
              }
            >
              <div className="rug-improvised-detail">
                {rugs?.result
                  // .filter((rug) => {
                  //   return categoryType === "all"
                  //     ? true
                  //     : rug.category.slug === categoryType;
                  // })
                  .map((rug) => (
                    <Link to={`/rug/${rug.slug}`}>
                      <div className="card">
                        <img
                          src={rug.designs[0].image}
                          onMouseOver={(e) =>
                            rug?.designs[1]
                              ? (e.currentTarget.src = rug?.designs[1]?.image)
                              : (e.currentTarget.src = rug?.designs[0]?.image)
                          }
                          onMouseOut={(e) =>
                            (e.currentTarget.src = rug?.designs[0]?.image)
                          }
                        />
                        <div className="rugName">
                          <h3>{rug.rugName}</h3>
                        </div>
                      </div>
                      {user.permissionLevel === 0 && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            marginTop: "10px",
                          }}
                        >
                          <button
                            className="btnLogin"
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(`/admin/edit-rug/${rug._id}`);
                            }}
                          >
                            Edit
                          </button>
                          <button
                            className="btnLogin"
                            style={{
                              backgroundColor: "red",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              setDialog({
                                isOpen: true,
                                _id: rug._id,
                              });
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      )}
                    </Link>
                  ))}
                {/* {rugs.data
                  .filter((rug) => {
                    return categoryType === "all"
                      ? true
                      : rug.category.slug === categoryType;
                  })
                  .map((rug) => (
                    <Link to={`/rug/${rug.slug}`}>
                      <div className="card">
                        <img
                          src={rug.designs[0].image}
                          onMouseOver={(e) =>
                            rug?.designs[1]
                              ? (e.currentTarget.src = rug?.designs[1]?.image)
                              : (e.currentTarget.src = rug?.designs[0]?.image)
                          }
                          onMouseOut={(e) =>
                            (e.currentTarget.src = rug?.designs[0]?.image)
                          }
                        />
                        <div className="rugName">
                          <h3>{rug.rugName}</h3>
                        </div>
                      </div>
                    </Link>
                  ))} */}
              </div>
            </InfiniteScroll>
          </div>
        </>
      ) : (
        <Loader />
      )}
      {dialog.isOpen && (
        <ConfirmDialog dialog={dialog} setDialog={setDialog} caller={"/rugs"} />
      )}
    </div>
  );
};

export default RugDesigns;
