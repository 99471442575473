import React from "react";
import "./Footer.scss";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/img/footer-logo.png";

import { useSelector } from "react-redux";
import {
  FaInstagramSquare,
  FaFacebookSquare,
  FaTwitterSquare,
} from "react-icons/fa";
const Footer = () => {
  const categories = useSelector((state) => state.category);
  const router = useNavigate();
  let date = new Date().getFullYear();
  return (
    <div className="footer-container">
      <div className="top-footer">
        <div className="about-footer">
          <img src={Logo} />
          <p>
            Looking for a creative rug designs to conceive and develop engaging
            integrated concepts and innovative visual experiences in your home
            décor globally. Novo Creation is to create the most beautiful,
            finest quality custom area rugs in the world. Our modern rugs
            handcrafted by our creative expert designers and weavers in your
            perfect size, shape and colors. We always provide the best customer
            service and offer you unparalleled value.
          </p>
        </div>
        <div className="quick-link">
          <h3>Quick Link</h3>
          <div className="links">
            <Link to="/home">
              <p>Home</p>
            </Link>
            <Link to="/designs">
              <p>Designs</p>
            </Link>
            <Link to="/about">
              <p>About</p>
            </Link>
            <Link to="/contact">
              <p>Contact</p>
            </Link>
            <Link to="/terms-and-conditions">
              <p>Terms and Contitions</p>
            </Link>
            <Link to="/privacy-policy">
              <p>Privacy Policy</p>
            </Link>
          </div>
        </div>
        <div className="designs-link">
          <h3>Designs</h3>
          <div className="links">
            {categories.data.map((category) => (
              // <Link
              //   to={`/designs/${category.slug}`}
              //   style={{ textDecoration: "none" }}
              // >
              <p
                onClick={() => {
                  router(`/designs/${category.slug}`);
                  window.location.reload();
                }}
              >
                {category.category}
              </p>
              // </Link>
            ))}
          </div>
        </div>
        <div className="contact-footer">
          <h3>Follow Us</h3>
          <div className="location-detail">
            <p className="social-media">
              <a
                target="_blank"
                href="https://www.facebook.com/Novo-Creation-102212082361801"
              >
                <FaFacebookSquare size={34} color="#fff" />
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/creationnovo/?fbclid=IwAR1J5PUWl_otDkG8pwppICIg9fIkZmuImg9CH4CZN_MHyvrFtlqqkF8VEwI"
              >
                <FaInstagramSquare size={34} color="#fff" />
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="bottom-footer">
        <p>Copyright {date} &copy; All rights reserved</p>
      </div>
    </div>
  );
};

export default Footer;
