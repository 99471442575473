import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import TopBar from "./components/TopBar";
import { getCategories } from "./redux/slices/category.slice";
import { getRugs } from "./redux/slices/rugs.slice";
import { getAboutContent } from "./redux/slices/about.slice";
import Footer from "./components/footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import CustomizedSnackbar from "./components/snackbar/CustomizedSnackbar";
import CustomizedModal from "./components/modal/CustomizedModal";
import Category from "./pages/Category/Category";
import { useEffect } from "react";
import { Designs } from "./pages/Designs/Designs";
import Fav from "./pages/Fav/Fav";
import Search from "./pages/Fav/Search";
import Verify from "./pages/Verify/Verify";
import Detail from "./pages/detail/Detail";
import ForgetPassword from "./pages/forgetPassword/ForgetPassword";
import ChangePassword from "./pages/forgetPassword/ChangePassword";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import RugDesigns from "./pages/RugDesigns/RugDesigns";
import { getDesignImages } from "./redux/slices/design.slice";
import { getContactContent } from "./redux/slices/contact.slice";
import { getSearchBanner } from "./redux/slices/searchBanner.slice";
import { getFavBanner } from "./redux/slices/favBanner.slice";
import { getRegisterImage } from "./redux/slices/registerImage.slice";
import { getLoginImage } from "./redux/slices/loginImage.slice";
import TermAndContition from "./pages/TermAndContition/TermAndContition";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import AddCategory from "./pages/Admin/AddCategory/AddCategory";
import AddRug from "./pages/Admin/AddRug/AddRug";
import Add from "./pages/Admin/Add";
import AdminLogin from "./pages/Admin/AdminLogin";
import { Toaster } from "react-hot-toast";
import Cart from "./pages/Cart/Cart";
// import { getCategories } from "./redux/slices/category.slice";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategories());
    // dispatch(getRugs());
  }, []);
  useEffect(() => {
    // dispatch(getAboutContent());
    // dispatch(getDesignImages());
    // dispatch(getContactContent());
    dispatch(getSearchBanner());
    dispatch(getFavBanner());
    // dispatch(getRegisterImage());
    // dispatch(getLoginImage());
  }, []);

  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(getCategories());
  // }, [dispatch]);

  const user = useSelector((state) => state.user);

  const location = useLocation();
  return (
    <div className="App">
      <Toaster />

      <CustomizedSnackbar />
      {/* <PublicRoutes /> */}
      <TopBar />
      <PublicRoutes />
      {/* {user.token !== "" ? <TopBar /> : <PublicRoutes />} */}
      {location.pathname !== "/login" && location.pathname !== "/register" && (
        <Footer />
      )}
    </div>
  );
}
const PublicRoutes = () => {
  const user = useSelector((state) => state.user);

  return (
    <Routes>
      {/* <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} /> */}
      <Route path="/forget-password" element={<ForgetPassword />} />
      <Route
        path="/reset-password/:userID/:uniqueString"
        element={<ChangePassword />}
      />
      <Route path="/home" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/designs" element={<Category />} />
      <Route path="/terms-and-conditions" element={<TermAndContition />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/designs/:slug" element={<RugDesigns />} />

      {/* <Route path="/designs/:slug" element={<Designs />} /> */}
      <Route path="/user/verify" element={<Verify />} />
      <Route path="/favorites" element={<Fav />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/rug/:slug" element={<Detail />} />
      <Route path="/search-result/:search" element={<Search />} />
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/forget-password" element={<Navigate to="/home" />} />

      {user.token !== "" ? (
        <Route path="/login" element={<Navigate to="/home" />} />
      ) : (
        <>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
        </>
      )}
      <Route path="/admin">
        {/* <Route index element={<Add />} /> */}
        {/* <Route path="login" element={<AdminLogin />} /> */}
        <Route path="add-category" element={<AddCategory />} />
        <Route path="edit-category/:id" element={<AddCategory edit={true} />} />
        <Route path="add-rug/:id" element={<AddRug />} />
        <Route path="edit-rug/:id" element={<AddRug edit={true} />} />
        {/* <Route path="add" element={<Add />}></Route> */}
      </Route>

      <Route path="*" element={<PageNotFound />}></Route>
    </Routes>
  );
};

export default App;
