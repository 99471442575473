import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { publicFetch } from "../../utils/fetch";
import BASE_URL from "../../utils/baseUrl";
import Cookies from "js-cookie";

export const loginAdmin = createAsyncThunk(
  "admin/login",
  async ({ payload, callback }, { dispatch }) => {
    try {
      dispatch(signUpRequest());
      const response = await publicFetch.post(
        `${BASE_URL}/api/v1/admin/login`,
        payload
      );
      if (response.status === 200) {
        console.log(response.data);
        Cookies.set("adminToken", response.data.token);
        dispatch(
          signUpSuccess({
            adminDetails: response.data,
          })
        );
        // localStorage.setItem("hrm-token", response.data.token);
        // localStorage.setItem("hrm-role", response.data.role);
        // response.data.newIdentification &&
        //   localStorage.setItem("hrm-id", response.data.newIdentification);
        callback("success", "admin LoggedIn Successfully");
      } else {
        dispatch(signUpFail(response.data.message));
        callback(response.data.message);
      }
    } catch (error) {
      dispatch(signUpFail(error.response.data.message));
      callback("error", error.response.data.message);
    }
  }
);

const initialState = {
  loading: false,
  adminDetails: null,
  Token: null,
  error: null,
  role: null,
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    signUpRequest: (state) => {
      state.loading = true;
    },
    signUpSuccess: (state, action) => {
      state.loading = false;
      state.adminDetails = action.payload.adminDetails;
      state.role = action.payload.role;
      state.error = null;
    },
    changePWSuccess: (state) => {
      state.loading = false;
      state.error = null;
    },
    signUpFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.adminDetails = null;
    },
    adminorizationSuccess: (state, action) => {
      state.loading = false;
      state.adminDetails = action.payload.admin;
      state.role = action.payload.role;
      state.error = null;
    },
    resetadminState: (state) => {
      state.loading = false;
      state.adminDetails = null;
      state.error = null;
    },
  },
});

export const {
  signUpRequest,
  signUpSuccess,
  signUpFail,
  adminorizationSuccess,
  changePWSuccess,
} = adminSlice.actions;

export default adminSlice.reducer;
