import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSingleRug } from "../../redux/slices/rugs.slice";
import { useParams } from "react-router";
import "./Detail.scss";
import {
  FaRegHeart,
  FaHeart,
  FaMinusSquare,
  FaPlusSquare,
} from "react-icons/fa";
import Loader from "../../components/Loader/Loader";
import LatestRugs from "../../components/Latest/LatestRugs";
import { useNavigate } from "react-router-dom";
import { SideBySideMagnifier } from "react-image-magnifiers";
import favouriteSlice, {
  addFav,
  getFav,
} from "../../redux/slices/favourite.slice";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CartContext from "../../store/CartContext";

const Detail = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useNavigate();

  // const [color, setColor] = useState("");
  const [image, setImage] = useState("");
  const [isLiked, setIsLiked] = useState(false);
  const [currentRugID, setCurrentRugID] = useState("");
  const [quantity, setQuantity] = useState(1);

  const fav = useSelector((state) => state.fav);
  var id;
  useEffect(async () => {
    const data = await dispatch(getSingleRug(params.slug));
    // setColor(data.payload.designs[0].color);
    setImage(data.payload.designs[0].image);
    setCurrentRugID(data.payload?._id);
    id = data.payload._id;
  }, [params]);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const user = useSelector((state) => state.user);

  useEffect(async () => {
    if (user.token !== "") {
      if (currentRugID) {
        const fav = await dispatch(getFav());
        if (fav.payload?.result[0]?.favorites) {
          fav.payload?.result[0]?.favorites?.forEach((favItem) => {
            if (favItem.rug._id === currentRugID) {
              setIsLiked(true);
            }
          });
        }
      }
    }
  }, [currentRugID]);
  const rugState = useSelector((state) => state.rugs);

  const addtofav = async (rugId) => {
    await dispatch(addFav(rugId));
    setIsLiked(!isLiked);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "fit-content",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 0,
  };
  // const rugData = useSelector((state) => state.rugs.singleData);

  // const [rugData, setRugData] = useState([]);

  // const fetchSingleRug = async () => {
  //   const data = await dispatch(getSingleRug(params.slug));
  //   setRugData(data.payload);
  // };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const cartContext = useContext(CartContext);
  return (
    <div className="detail-container">
      {rugState.getSingleStatus === "SUCCESS" ? (
        <div className="single-rug-detail">
          <div className="image">
            {/* {rugData._id} */}
            {/* <GlassMagnifier
              imageSrc={rugState.singleData?.designs[0]?.image}
              imageAlt="Example"
            /> */}

            {/* <SideBySideMagnifier
              className="rug-image"
              imageSrc={image}
              largeImageSrc={image}
              imageAlt="rugImage"
              fillAvailableSpace="true"
              overlayBoxOpacity="0.2"
              overlayOpacity="0.7"
              overlayBackgroundColor="#000"
              overlayBoxColor="#fff"
              zoomContainerBorder="1px solid white"
              overlayBoxImageSize="200px"
              transitionSpeed="0.8"
              fillAlignTop="50"
              fillGapBottom="80"
              fillGapRight="30"
              style={{ objectFit: "cover" }}
            /> */}
            <img className="rug-image" src={image} alt="rug image" />
          </div>
          <div className="detail">
            {/* {rugData._id} */}
            <div className="title">
              <span className="rugName">{rugState.singleData.rugName}</span>

              {user.token !== "" ? (
                <span
                  className="addtofav"
                  onClick={() => addtofav(rugState.singleData._id)}
                >
                  {isLiked ? (
                    <FaHeart className="fav" size={22} color="#d9112c" />
                  ) : (
                    <FaRegHeart className="fav" size={22} color="#868585" />
                  )}
                </span>
              ) : (
                <span className="addtofav" onClick={() => history("/login")}>
                  {/* <FaHeart className="fav" size={22} color="#d9112c" /> */}

                  <FaRegHeart className="fav" size={22} color="#868585" />
                </span>
              )}
            </div>
            <hr className="horizontal-divider" />
            <div className="design">
              <span className="type">Category:</span>
              <span className="value">
                {rugState.singleData.category.category}
              </span>
            </div>
            <hr className="horizontal-divider" />
            <div className="design">
              <span className="type">Price:</span>
              <span className="value">$ {rugState.singleData.price}</span>
            </div>
            <div className="design">
              <span className="type">Quantity:</span>
              <span className="quantity">
                <FaMinusSquare
                  className="quantity-btn"
                  onClick={() => {
                    quantity !== 1 && setQuantity(quantity - 1);
                  }}
                />
                <span className="quantity-value">{quantity}</span>
                <FaPlusSquare
                  className="quantity-btn"
                  onClick={() => {
                    setQuantity(quantity + 1);
                  }}
                />
              </span>
            </div>
            <hr className="horizontal-divider" />
            <div className="colors">
              <span className="type">Designs:</span>
              <div className="color-image">
                {/* <div>
                  <p className="value">{color}</p>
                </div> */}
                <div className="designImages">
                  {rugState.singleData.designs.map((rugDesign) => (
                    <img
                      className={
                        rugDesign.image == image ? "image active" : "image"
                      }
                      src={rugDesign.image}
                      onMouseEnter={() => {
                        // setColor(rugDesign.color);
                        setImage(rugDesign.image);
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="add-to-cart">
              <button
                className="add-to-cart-btn"
                onClick={() => {
                  cartContext.cartAddHandler({
                    _id: rugState.singleData._id,
                    name: rugState.singleData.rugName,
                    image: rugState.singleData.designs[0].image,
                    quantity,
                    unitPrice: rugState.singleData.price,
                    totalPrice: rugState.singleData.price * quantity,
                  });
                }}
              >
                Add to Cart
              </button>
            </div>
            {/* <hr className="horizontal-divider" /> */}

            {/* <div className="room-design">
              <button className="view-btn" onClick={() => setOpen(true)}>
                View Room Design
              </button>
            </div> */}
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                {rugState?.singleData?.homeLayout != null ? (
                  <img
                    className="homeLayoutImage"
                    src={rugState?.singleData?.homeLayout}
                    style={{ height: "600px", width: "fit-content" }}
                  />
                ) : (
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    className="no-room-image"
                  >
                    No room design of this rug.
                  </Typography>
                )}
              </Box>
            </Modal>
          </div>
        </div>
      ) : (
        <Loader />
      )}
      <div>{/* <LatestRugs /> */}</div>
    </div>
  );
};

export default Detail;
