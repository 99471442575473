import React from "react";
import "./title.scss";

const SubHeading = (title) => {
  return (
    // <div className="cat-heading">
    //   <h2 className="title">{title.title}</h2>
    //   <p>
    //     <span className="subtitle">{title.subtitle}</span>
    //   </p>
    // </div>
    <div className="design-heading">
      <p>
        <span className="subtitle">{title.subtitle}</span>
      </p>
      <h2 className="title">{title.title}</h2>
    </div>
    //      <div className="cat-heading">
    //      <h2 className="title">View By Designs</h2>
    //      <p>
    //        <span className="subtitle">explore rugs by style</span>
    //      </p>
    //    </div>
  );
};

export default SubHeading;
