import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "../add.module.css";

import { FiCamera } from "react-icons/fi";
import { AiOutlineLoading3Quarters, AiFillCloseCircle } from "react-icons/ai";
import axios from "axios";
// import { GurkhaContext } from "../../../App";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import BASE_URL from "../../../utils/baseUrl";
function AddRug({ edit }) {
  const navigate = useNavigate();
  const params = useParams();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    user.permissionLevel === 0 && navigate("/", { replace: true });
  }, [user, navigate]);

  //   const { teams } = useContext(GurkhaContext);

  const [isLoading, setIsLoading] = useState(false);
  const [gallery, setGallery] = useState([]);
  const [galleryFiles, setGalleryFiles] = useState([]);
  const rugs = useSelector((state) => state.rugs);
  if (edit && (!rugs.currentData || rugs?.currentData?.length === 0)) {
    navigate("/", { replace: true });
  }
  const curRug = rugs.currentData.find((el) => el._id === params.id);
  console.log(curRug, rugs, params.id);
  const [rugName, setRugName] = useState(edit ? curRug?.rugName : "");

  //   useEffect(() => {
  //     if (params.id) {
  //       let active_partner = teams?.filter((el) => el._id === params.id)[0];

  //       setTeamDetails({
  //         name: active_partner?.name || "",
  //         position: active_partner?.position || "",
  //         description: active_partner?.description || "",
  //         twitter_link: active_partner?.twitter_link || "",
  //         facebook_link: active_partner?.facebook_link || "",
  //         linkedin_link: active_partner?.linkedin_link || "",
  //         telegram_link: active_partner?.telegram_link || "",
  //       });

  //       setCoverImage(
  //         `${process.env.REACT_APP_SERVER_DOMAIN}${active_partner?.image}`
  //       );
  //     }
  //   }, [params, teams]);

  const handleGalleryChange = (e) => {
    if (gallery.length >= 3) return;
    if (e.target.files[0]) {
      const galleryarray = Array.from(e.target.files);
      console.log(galleryarray);
      const galleryImages = galleryarray.map((image) => {
        return URL.createObjectURL(image);
      });
      setGallery([...gallery, ...galleryImages]);
      setGalleryFiles([...galleryFiles, ...galleryarray]);
    }
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();

    setIsLoading(true);

    let data = new FormData();

    data.append("rugName", rugName);
    data.append("slug", rugName.toLowerCase().replaceAll(" ", "_"));
    !edit && data.append("category", params.id);

    galleryFiles.map((el) => data.append("designs", el));

    if (edit) {
      axios
        .patch(`${BASE_URL}/api/v1/rugs/${params.id}`, data, {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        })
        .then((res) => {
          toast.success("Successfully edited. Please refresh the page");
          console.log("successful");
          navigate("/", { replace: true });
          setIsLoading(false);
        })
        .catch((err) => {
          toast.error("Failed");
          console.log(err);
          setIsLoading(false);
        });
    } else {
      axios
        .post(`${BASE_URL}/api/v1/rugs`, data, {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        })
        .then((res) => {
          console.log("successful");
          toast.success("Successfully added.");
          navigate("/", { replace: true });
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Failed");
          setIsLoading(false);
        });
    }
  };

  return (
    <form
      className={styles.form}
      onSubmit={handleSubmitForm}
      disabled={isLoading}
    >
      <div className={styles.container}>
        <h1>{edit ? "Edit" : "Add"} Rug</h1>

        <div className={styles.row}>
          <div className={styles.inputs}>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              name="name"
              placeholder="Rug's Name"
              value={rugName}
              onChange={(e) => {
                setRugName(e.target.value);
              }}
            />
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.inputs}>
            <label htmlFor="gallery">Gallery</label>
            <div className={styles.galleryInput}>
              <p>Select Images</p>
              <input
                type="file"
                accept=".jpg, .jpeg, .png, .heic, .HEIC"
                name="gallery"
                max={3}
                multiple
                onChange={handleGalleryChange}
              />
            </div>
          </div>
          <div className={styles.gallery}>
            {gallery?.map((image, i) => {
              return (
                <div key={i}>
                  <img src={image} alt="gallery" />
                  <AiFillCloseCircle
                    onClick={() => {
                      let temp = [...gallery];
                      let temp1 = [...galleryFiles];
                      temp.splice(i, 1);
                      temp1.splice(i, 1);
                      setGallery(temp);
                      setGalleryFiles(temp1);
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>

        <button disabled={isLoading}>
          {isLoading ? <AiOutlineLoading3Quarters /> : "Submit"}
        </button>
      </div>
    </form>
  );
}

export default AddRug;
