import React, { useEffect, useState } from "react";
import "./Fav.scss";
import { Link } from "react-router-dom";
import Banner from "../../components/banner/Banner";
import { useDispatch, useSelector } from "react-redux";
import { searchRug } from "../../redux/slices/rugs.slice";
import Loader from "../../components/Loader/Loader";
import { useParams } from "react-router";
import { getSearchBanner } from "../../redux/slices/searchBanner.slice";

const Search = () => {
  const params = useParams();

  const dispatch = useDispatch();

  const [searchedData, setSearchedData] = useState([]);

  useEffect(async () => {
    const result = await dispatch(searchRug(params.search));
    // setSearchedData(result.payload);
  }, [params]);
  useEffect(() => {
    dispatch(getSearchBanner());
  }, [dispatch]);

  const rugState = useSelector((state) => state.rugs);
  const searchState = useSelector((state) => state.searchBanner);
  return (
    <div className="fav-container">
      {searchState?.status === "SUCCESS" ? (
        <Banner
          title="Search Results"
          subtitle="Find the rug"
          image={searchState?.data[0]?.searchBanner}
        />
      ) : (
        <Loader />
      )}

      {rugState.searchStatus === "SUCCESS" ? (
        <div className="fav-body">
          {rugState.searchedData.length > 0 ? (
            <div className="rug-improvised-detail">
              {rugState.searchedData.map((searchList) => (
                <Link to={`/rug/${searchList.slug}`}>
                  <div className="card">
                    <img src={searchList.designs[0].image} />
                    <div className="rugName">
                      <h3>{searchList.rugName}</h3>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          ) : (
            <div className="no-search-result">
              <h1>Sorry!!! No rug found</h1>
            </div>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Search;
