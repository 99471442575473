import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactSearchBox from "react-search-box";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { setToken } from "../redux/slices/user.slice";
import "./topbar.scss";
import Logo from "../assets/img/text-logo6.png";
// import Logo from "../assets/img/logo.png";
// import TextLogo from "../assets/img/text-logo.png";
import { MdSearch } from "react-icons/md";
import { FiUser } from "react-icons/fi";
import { IoCartOutline } from "react-icons/io5";
import {
  IoPersonAddOutline,
  IoLogInOutline,
  IoLogOutOutline,
  IoHeartOutline,
} from "react-icons/io5";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ContentCut from "@mui/icons-material/ContentCut";
import Cookies from "js-cookie";

import { HiMenu } from "react-icons/hi";
import { useCartContext } from "../store/CartContext";

const TopBar = () => {
  const user = useSelector((state) => state.user);
  console.log(user);
  const [openMenu, setOpenMenu] = useState(false);
  const history = useNavigate();
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.category);
  const cartContext = useCartContext();
  console.log(cartContext);

  const location = useLocation();
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleSumbit = (e) => {
    e.preventDefault();
    history(`/search-result/${searchTerm}`);
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    Cookies.remove("token");
    Cookies.remove("user");
    setOpenMenu(false);

    // localStorage.clear();
    dispatch(setToken(""));
    history("/login");
  };
  return (
    <div className="navigation-bar">
      <div className={!openMenu ? "normal-menu" : "open-menu"}>
        <header>
          <nav>
            <div
              className="icon"
              onClick={() => history(`/home`)}
              style={{ cursor: "pointer" }}
            >
              <img src={Logo} />
              {/* <img src={TextLogo} /> */}
            </div>
            <div
              className={!openMenu ? "menu-container" : "menu-container show"}
            >
              <ul className="nav-list">
                <li
                  className={location.pathname === "/home" ? "active" : ""}
                  onClick={() => setOpenMenu(false)}
                >
                  <Link to="/home">
                    <a>Home</a>
                  </Link>
                </li>
                <li
                  className={location.pathname === "/designs" ? "active" : ""}
                  onClick={() => setOpenMenu(false)}
                >
                  <Link to="/designs">
                    <a>Designs</a>
                  </Link>
                </li>
                {/* <li
                  className={
                    location.pathname === "/designs/all" ||
                    (categories?.data?.map(
                      (category) =>
                        location.pathname === `/designs/${category?.slug}`
                    ) &&
                      location.pathname !== "/home" &&
                      location.pathname !== "/contact" &&
                      location.pathname !== "/login" &&
                      location.pathname !== "/register" &&
                      location.pathname !== "/search" &&
                      location.pathname !== "/about")
                      ? "active"
                      : ""
                  }
                  onClick={() => setOpenMenu(false)}
                >
                  <Link to="/designs/all">
                    <a>Designs</a>
                  </Link>
                </li> */}
                <li
                  className={location.pathname === "/about" ? "active" : ""}
                  onClick={() => setOpenMenu(false)}
                >
                  <Link to="/about">
                    <a>About</a>
                  </Link>
                </li>
                <li
                  className={location.pathname === "/contact" ? "active" : ""}
                  onClick={() => setOpenMenu(false)}
                >
                  <Link to="/contact">
                    <a>Contact</a>
                  </Link>
                </li>
              </ul>
              <div className="icons">
                <div
                  className={isSearchOpen ? "searchfield show" : "searchfield"}
                >
                  <form onSubmit={handleSumbit}>
                    <input
                      type="text"
                      placeholder="Search Here"
                      onChange={handleSearchChange}
                    />
                    {/* <input type="submit" value="Submit" /> */}
                  </form>
                </div>
                <div
                  className="cart-icon"
                  onClick={() => {
                    history("/cart");
                  }}
                >
                  <IoCartOutline size={22} />
                  {cartContext?.cart?.items?.length !== 0 && (
                    <span className="cart-number">
                      {cartContext.cart.items.length}
                    </span>
                  )}
                </div>
                <MdSearch
                  className="search"
                  onClick={() => setSearchOpen(!isSearchOpen)}
                  size={22}
                />

                <FiUser id="user" size={22} onClick={handleClick} />
                {user.token === "" ? (
                  <Menu
                    id="user"
                    className="dropdown-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <p style={{ paddingLeft: "15px" }}>Account</p>
                    <MenuItem onClick={handleClose}>
                      <Link to="/login">
                        <button
                          className="btnLogin"
                          onClick={() => setOpenMenu(false)}
                        >
                          <IoLogInOutline size={16} /> Login
                        </button>
                      </Link>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <Link to="/register">
                        <button
                          className="btnRegister"
                          onClick={() => setOpenMenu(false)}
                        >
                          <IoPersonAddOutline size={16} /> Register
                        </button>
                      </Link>
                    </MenuItem>
                  </Menu>
                ) : (
                  <Menu
                    id="user"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <p style={{ paddingLeft: "15px" }}>Profile</p>

                    <MenuItem onClick={handleClose}>
                      <Link to="/favorites" style={{ textDecoration: "none" }}>
                        <button
                          className="btnLogin"
                          onClick={() => setOpenMenu(false)}
                        >
                          <IoHeartOutline
                            size={18}
                            style={{ fontSize: "20px" }}
                          />{" "}
                          My Favorites
                        </button>
                      </Link>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <button className="btnRegister" onClick={logout}>
                        <IoLogOutOutline size={16} /> Logout
                      </button>
                    </MenuItem>
                  </Menu>
                )}
              </div>
            </div>
            <div className="menu-list" onClick={() => setOpenMenu(!openMenu)}>
              <HiMenu size={26} />
            </div>
          </nav>
        </header>
      </div>
    </div>
  );
};

export default TopBar;
