import React, { useState } from "react";
import { publicFetch } from "../../utils/fetch";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../redux/slices/snackbar.slice";
import { CircularProgress } from "@material-ui/core";
import "./ChangePassword.scss";

const ForgetPassword = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const validate = () => {
    if (email === "") {
      setEmailError("Email is required");
      return false;
    } else {
      setEmailError("");
    }
    return true;
  };

  const confirmEmail = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        setLoading(true);

        const data = { email };
        const response = await publicFetch.post(
          `/api/v1/forget-password`,
          data
        );
        setLoading(false);

        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: response.data.message,
          })
        );
      } catch (error) {
        setLoading(true);
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: error.response.data.message,
          })
        );
        setLoading(false);
      }
    }
  };
  return (
    <div className="forget-container">
      <form className="check-email-container" onSubmit={confirmEmail}>
        <h2>Verify Your Email</h2>
        <div className="input-field">
          <label for="email">Enter Your Email</label>
          <input
            type="email"
            name="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <span className="error">{emailError}</span>
        </div>
        {loading ? (
          <button className="confirmEmailBtn" disabled>
            <CircularProgress size={18} color={"#fff"} />
          </button>
        ) : (
          <button className="confirmEmailBtn" type="submit">
            Confirm Email
          </button>
        )}
      </form>
    </div>
  );
};

export default ForgetPassword;
