import {
  createContext,
  useReducer,
  useEffect,
  useState,
  useContext,
} from "react";
import { storeLocal } from "../utils/utils";

const CartContext = createContext(null);

export const useCartContext = () => {
  const cartContext = useContext(CartContext);
  console.log(cartContext);
  if (!cartContext) {
    throw new Error("not valid");
  }
  return cartContext;
};

const cartReducer = (state, action) => {
  const { type } = action;
  if (type === "INIT") {
    const { unitPrice, quantity } = action.payload;
    const items = [...state.items, action.payload];
    storeLocal({ items, total: unitPrice * quantity + state.total });
    return { items, total: unitPrice * quantity + state.total };
  } else if (type === "INCREMENT") {
    const { _id } = action.payload;
    const duplicateCart = state.items.slice();
    const toIncrease = duplicateCart.find((el) => el._id === _id);
    const total = state.total + toIncrease.unitPrice;
    const items = duplicateCart.map((el) => {
      if (el._id === _id) {
        return {
          ...el,
          quantity: el.quantity + 1,
        };
      }
      return el;
    });
    storeLocal({ items, total });
    return { items, total };
  } else if (type === "DECREMENT") {
    const { _id } = action.payload;
    const duplicateCart = state.items.slice();
    const toIncrease = duplicateCart.find((el) => el._id === _id);
    const total = state.total - toIncrease.unitPrice;
    if (toIncrease.quantity === 1) {
      const items = duplicateCart.filter(
        ({ _id }) => !(_id === toIncrease._id)
      );
      storeLocal({ items, total });
      return { items, total };
    }
    const items = duplicateCart.map((el) => {
      if (el._id === _id) {
        return {
          ...el,
          quantity: el.quantity - 1,
        };
      }
      return el;
    });
    storeLocal({ items, total });
    return { items, total };
  } else if (type === "STORED") {
    return action.payload;
  } else if (type === "REMOVE") {
    const { _id } = action.payload;
    const deletingItem = state.items.find((el) => el._id === _id);
    const items = state.items
      .slice()
      .filter(({ _id }) => !(_id === deletingItem._id));
    const total = state.total - deletingItem.quantity * deletingItem.unitPrice;
    storeLocal({ items, total });
    return {
      items,
      total,
    };
  } else {
    return { ...state };
  }
};

export const CartContextProvider = ({ children }) => {
  const initCartState = {
    total: 0,
    items: [],
  };
  const [cart, dispatchCart] = useReducer(cartReducer, initCartState);
  const [bumpWishlist, setBumpWishlist] = useState(false);
  const cartAddHandler = (cartItem) => {
    dispatchCart({ type: "INIT", payload: cartItem });
  };
  const itemAddHandler = (details) => {
    dispatchCart({ type: "INCREMENT", payload: details });
  };

  const itemDecreaseHandler = (details) => {
    dispatchCart({ type: "DECREMENT", payload: details });
  };
  const cartRemoveHandler = (details) => {
    dispatchCart({ type: "REMOVE", payload: details });
  };
  useEffect(() => {
    const storedCart = localStorage.getItem("cart")
      ? JSON.parse(localStorage.getItem("cart"))
      : {
          items: [
            {
              _id: "",
              name: "",
              image: "",
              quantity: 0,
              unitPrice: 0,
              totalPrice: 0,
            },
          ],
          total: 0,
        };
    cart.total === 0 &&
      storedCart.total !== 0 &&
      dispatchCart({ type: "STORED", payload: storedCart });
  }, [cart]);

  return (
    <CartContext.Provider
      value={{
        cart,
        cartAddHandler,
        itemAddHandler,
        cartRemoveHandler,
        bumpWishlist,
        setBumpWishlist,
        itemDecreaseHandler,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartContext;
