import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { publicFetch } from "../../utils/fetch";
import { setSnackbar } from "./snackbar.slice";
import { LOADING, SUCCESS, FAILED } from "../../utils/status";

export const getRugs = createAsyncThunk(
  "rugs/getRugs",
  async (filters, { dispatch, rejectWithValue, getState }) => {
    try {
      const limit = 8;
      const page = 1;

      // const privateFetch = publicFetch(getState().user.token);
      const response = await publicFetch.get(
        // `/api/v1/rugs?limit=${limit}&page=${page}`
        `/api/v1/rugs?limit=${limit}&sortby=rugName&order=1`

        // `/api/v1/rugs?status=${status}&limit=${limit}&page=${page}`
      );
      return response.data;
    } catch (error) {
      const responseMessage = error.response.data.message;
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: responseMessage,
        })
      );
      return rejectWithValue(responseMessage);
    }
  }
);
export const getLatestRugs = createAsyncThunk(
  "rugs/getLetestRugs",
  async (filters, { dispatch, rejectWithValue, getState }) => {
    try {
      const limit = 10;
      const page = 1;

      // const privateFetch = publicFetch(getState().user.token);
      const response = await publicFetch.get(
        `/api/v1/rugs?limit=${limit}&page=${page}&sortby=createdAt&order=-1&isall=1`
        // `/api/v1/rugs`
        // `/api/v1/rugs?status=${status}&limit=${limit}&page=${page}`
      );
      return response.data;
    } catch (error) {
      const responseMessage = error.response.data.message;
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: responseMessage,
        })
      );
      return rejectWithValue(responseMessage);
    }
  }
);

export const searchRug = createAsyncThunk(
  "rugs/searchRug",
  async (searchTerm, { dispatch, rejectWithValue, getState }) => {
    try {
      //   const status = filters.status;
      // const limit = 10;
      // const page = 1;

      // const privateFetch = publicFetch(getState().user.token);
      const response = await publicFetch.get(
        `/api/v1/rugs/search/${searchTerm}`
        // `/api/v1/rugs?status=${status}&limit=${limit}&page=${page}`
      );
      return response.data.result;
    } catch (error) {
      const responseMessage = error.response.data.message;
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: responseMessage,
        })
      );
      return rejectWithValue(responseMessage);
    }
  }
);

export const getSingleRug = createAsyncThunk(
  "rugs/getSingleRug",
  async (slug, { dispatch, rejectWithValue, getState }) => {
    try {
      //   const status = filters.status;
      // const limit = 10;
      // const page = 1;

      // const privateFetch = publicFetch(getState().user.token);
      const response = await publicFetch.get(
        `/api/v1/rugs/${slug}`
        // `/api/v1/rugs?status=${status}&limit=${limit}&page=${page}`
      );
      return response.data.result;
    } catch (error) {
      const responseMessage = error.response.data.message;
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: responseMessage,
        })
      );
      return rejectWithValue(responseMessage);
    }
  }
);
const rugsSlice = createSlice({
  name: "rugs",
  initialState: {
    data: [],
    latestData: [],
    singleData: [],
    searchedData: [],
    currentData: [],
    images: [],
    totalCount: 0,
    searchStatus: "",
    page: 0,
    status: "",
    message: "",
    getSingleStatus: "",
  },
  reducers: {
    // New action for storing fetched data
    setRugsData: (state, action) => {
      state.currentData = action.payload.result?.result;
    },
  },

  extraReducers: {
    [getRugs.pending]: (state, action) => {
      return { ...state, getStatus: LOADING };
    },
    [getRugs.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        getStatus: SUCCESS,
        data: payload.result,
        totalCount: payload.totalCount,
        page: payload.page,
      };
    },
    [getRugs.rejected]: (state, action) => {
      return { ...state, getStatus: FAILED };
    },
    [getLatestRugs.pending]: (state, action) => {
      return { ...state, getStatus: LOADING };
    },
    [getLatestRugs.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        getStatus: SUCCESS,
        latestData: payload.result,
        totalCount: payload.totalCount,
        page: payload.page,
      };
    },
    [getLatestRugs.rejected]: (state, action) => {
      return { ...state, getStatus: FAILED };
    },
    [getSingleRug.pending]: (state, action) => {
      return { ...state, getSingleStatus: LOADING };
    },
    [getSingleRug.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        getSingleStatus: SUCCESS,
        singleData: payload,
      };
    },
    [getSingleRug.rejected]: (state, action) => {
      return { ...state, getSingleStatus: FAILED };
    },
    [searchRug.pending]: (state, action) => {
      return { ...state, searchStatus: LOADING };
    },
    [searchRug.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        searchStatus: SUCCESS,
        searchedData: payload,
      };
    },
    [searchRug.rejected]: (state, action) => {
      return { ...state, searchStatus: FAILED };
    },
  },
});
export const { setRugsData } = rugsSlice.actions;

export default rugsSlice.reducer;
