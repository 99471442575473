export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const storeLocal = ({ items, total }) => {
  localStorage.setItem("cart", JSON.stringify({ items, total }));
};

export const giveUnitPrice = (total, index) => {
  switch (index) {
    case 0:
      return Math.floor(total / 4);
    case 1:
      return Math.floor(total / 2);
    case 2:
      return Math.floor(total / 1.33);
    case 3:
      return total;
    case 4:
      return total * 2;
    default:
      return 0;
  }
};
