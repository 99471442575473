import React from "react";
import BannerImg from "../../assets/img/bg-image.png";
import Foral from "../../assets/img/Foral.png";
import Geometry from "../../assets/img/Geometry.png";
import Ocean from "../../assets/img/Ocean.png";
import BASE_URL from "../../utils/baseUrl";
import "./Banner.scss";

const Banner = (banner) => {
  return (
    <div className="banner-container">
      <div className="banner-design">
        {banner.rugDesign ? (
          <>
            {banner.banner != null ? (
              <img className="bg-image" src={`${banner.banner}`} />
            ) : (
              <img className="bg-image" src={BannerImg} />
            )}
          </>
        ) : (
          <>
            {banner.image != null ? (
              <img className="bg-image" src={`${BASE_URL}${banner.image}`} />
            ) : (
              <img className="bg-image" src={BannerImg} />
            )}
          </>
        )}

        <div className="banner-text">
          {banner.logo != null ? (
            <img src={banner.logo} className="icon"></img>
          ) : (
            ""
          )}
          <h1>{banner.title}</h1>
          <p>{banner.subtitle}</p>
        </div>
      </div>
    </div>
  );
};

export default Banner;
