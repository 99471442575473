import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { privateFetch, privateFetchFormData } from "../../utils/fetch";
import { setSnackbar } from "./snackbar.slice";

export const getSearchBanner = createAsyncThunk(
  "search/getSearchBanner",
  async (_, { dispatch, rejectWithValue, getState }) => {
    try {
      // const privateFetch = publicFetch(getState().user.token);
      const response = await privateFetch.get(
        // `/api/v1/categories?page=1&limit=10`
        `/api/v1/search/banner`
      );
      return response.data;
    } catch (error) {
      const responseMessage = error.response.data.message;
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: responseMessage,
        })
      );
      return rejectWithValue(responseMessage);
    }
  }
);

const searchBannerSlice = createSlice({
  name: "searchBanner",
  initialState: {
    data: [],
    totalCount: 0,
    page: 0,
    status: "",
    editStatus: "",
    message: "",
  },
  extraReducers: {
    [getSearchBanner.pending]: (state, action) => {
      state.status = "LOADING";
    },
    [getSearchBanner.fulfilled]: (state, { payload }) => {
      state.data = payload.result;
      state.status = "SUCCESS";
    },
    [getSearchBanner.rejected]: (state, action) => {
      state.status = "FAILED";
    },
  },
});
export default searchBannerSlice.reducer;
