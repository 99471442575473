import React, { useEffect, useState } from "react";
import AboutImg from "../../assets/img/login.jpeg";
import "./Contact.scss";
import Banner from "../../components/banner/Banner";
import { ImFacebook2 } from "react-icons/im";
import SubHeading from "../../components/heading/SubHeading";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import { CircularProgress } from "@material-ui/core";
import { setSnackbar } from "../../redux/slices/snackbar.slice";

import {
  FaInstagramSquare,
  FaFacebookSquare,
  FaTwitterSquare,
} from "react-icons/fa";
import { getContactContent } from "../../redux/slices/contact.slice";
import BASE_URL from "../../utils/baseUrl";
import { postMail } from "../../redux/slices/mail.slice";

const About = () => {
  const dispatch = useDispatch();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);

  const [fullNameError, setFullNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const validate = () => {
    if (fullName === "") {
      setFullNameError("Full Name is Required");
      setLoading(false);
      return false;
    } else {
      setFullNameError("");
    }

    if (email === "") {
      setEmailError("Email is Required");
      setLoading(false);
      return false;
    } else {
      setEmailError("");
    }
    if (message === "") {
      setMessageError("Message is Required");
      setLoading(false);

      return false;
    } else {
      setMessageError("");
    }
    return true;
  };
  useEffect(() => {
    dispatch(getContactContent());
  }, [dispatch]);

  const mailState = useSelector((state) => state.mail);
  const contactState = useSelector((state) => state.contact);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const onHandleSubmit = (e) => {
    e.preventDefault();
    const data = {
      fullName,
      email,
      phone,
      companyName,
      message,
    };
    if (validate()) {
      dispatch(postMail(data));
    }
  };

  return (
    <div className="contact-container">
      {contactState?.status === "SUCCESS" ? (
        <>
          {/* <div className="banner-design">
        <img src={BannerImg} />
        <div className="banner-text">
          <h1>Contact The Rug</h1>
          <p>Get in touch with us</p>
        </div>
      </div> */}
          <Banner
            title="Contact The Rug"
            subtitle="Get in touch with us"
            image={contactState?.data[0].contactBanner}
          />
          <div className="contact-body">
            {/* <div className="contact-heading">
          <p>
            <span className="subtitle">Where can you contact us</span>
          </p>
          <h2 className="title">Contact Us</h2>
        </div> */}
            <SubHeading
              title="Where can you contact us"
              subtitle="Contact Us"
            />

            <div className="contact-detail">
              <div class="imagebox">
                <img
                  src={`${BASE_URL}${contactState?.data[0]?.contactImage}`}
                  loading="lazy"
                />
              </div>

              <div class="textbox">
                <form action="" class="contact" onSubmit={onHandleSubmit}>
                  <div className="input-field">
                    <label for="fullName">Full Name*</label>
                    <input
                      type="text"
                      name="fullName"
                      id="fullName"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                    />
                    <span className="error">{fullNameError}</span>
                  </div>
                  <div className="input-field">
                    <label for="email">Email*</label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <span className="error">{emailError}</span>
                  </div>

                  <div className="input-field">
                    <label for="company">Company Name</label>
                    <input
                      type="text"
                      name="company"
                      id="company"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                    {/* <span className="error">{companyNameError}</span> */}
                  </div>

                  <div className="input-field">
                    <label for="phone">Phone</label>
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    {/* <span className="error">{phoneError}</span> */}
                  </div>

                  <div className="input-field">
                    <label for="message">Message*</label>
                    <textarea
                      type="text"
                      name="message"
                      id="message"
                      rows="10"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                    <span className="error">{messageError}</span>
                  </div>
                  {mailState.addStatus === "" ||
                  mailState.addStatus === "SUCCESS" ||
                  mailState.addStatus === "FAILED" ? (
                    <button className="contact-btn">
                      {/* <CircularProgress size={18} color={"#fff"} /> */}
                      Send Message
                    </button>
                  ) : (
                    <button className="contact-btn" disabled>
                      <CircularProgress size={18} color={"#fff"} />
                      {/* Send Message */}
                    </button>
                  )}
                </form>
                {/* <div className="address">
                  <h3>Address</h3>
                  <p>Budhanilkantha-7,</p>
                  <p>Golfutar,</p>
                  <p>Kathmandu, Nepal</p>
                </div>
                <div className="contact">
                  <h3>Contact</h3>
                  <p>+977-9851000783</p>
                </div> */}
                {/* <div className="follow">
                  <h3>Follow Us</h3>
                  <p className="social-media">
                    <a>
                      <FaFacebookSquare size={34} color="#3b5998" />
                    </a>
                    <a>
                      <FaInstagramSquare size={34} color="#ef11be" />
                    </a>
                    <a>
                      <FaTwitterSquare size={34} color="#00acee" />
                    </a>
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default About;
