import { useEffect } from "react";
// import Team from "../../components/Aboutus/Team/Team";
// import CompanyOnBoard from "../CompanyOnBoard/CompanyOnBoard";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Button } from "@material-ui/core";
import styles from "./add.module.css";
import { useSelector } from "react-redux";
function Add() {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  useEffect(() => {
    user.permissionLevel === 0 && navigate("/", { replace: true });
  }, [user, navigate]);

  return (
    <main
      className={styles.container}
      style={{
        margin: "30px 3px",
        flexDirection: "row",
      }}
    >
      <Button
        onClick={() => {
          navigate("/admin/add-rug");
        }}
      >
        Add Rug
      </Button>
      <Button
        onClick={() => {
          navigate("/admin/add-category");
        }}
      >
        Add Category
      </Button>
    </main>
  );
}

export default Add;
