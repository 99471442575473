import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getPrivateFetch,
  privateFetch,
  privateFetchFormData,
} from "../../utils/fetch";
import { FAILED, LOADING, SUCCESS } from "../../utils/status";
import { setSnackbar } from "./snackbar.slice";

export const getHomeImage = createAsyncThunk(
  "home/getImages",
  async (_, { dispatch, rejectWithValue, getState }) => {
    try {
      const privateFetch = getPrivateFetch(getState().user.token);
      const response = await privateFetch.get(`/api/v1/home`);
      return response.data;
    } catch (error) {
      const responseMessage = error.response.data.message;
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: responseMessage,
        })
      );
      return rejectWithValue(responseMessage);
    }
  }
);

const homeSlice = createSlice({
  name: "home",
  initialState: {
    data: [],
    images: [],
    totalCount: 0,
    page: 0,
    status: "",
    addStatus: "",
    editStatus: "",
    deleteStatus: "",
  },

  extraReducers: {
    [getHomeImage.pending]: (state, action) => {
      return { ...state, status: LOADING };
    },
    [getHomeImage.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        status: SUCCESS,
        data: payload.result,
      };
    },
    [getHomeImage.rejected]: (state, action) => {
      return { ...state, status: FAILED };
    },
  },
});

// export const { setImages, deleteImage } = rugsSlice.actions;

export default homeSlice.reducer;
