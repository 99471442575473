import { useState, useEffect } from "react";
// import "./Auth.scss";
import "./register.scss";
import { publicFetch } from "../../utils/fetch";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addNewUser, setToken, setUser } from "../../redux/slices/user.slice";
import BASE_URL from "../../utils/baseUrl";
import { setSnackbar } from "../../redux/slices/snackbar.slice";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomizedModal from "../../components/modal/CustomizedModal";
import { getRegisterImage } from "../../redux/slices/registerImage.slice";
// import LoginImage from "../../assets/login.jpeg";

function Register() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const userState = useSelector((state) => state.user);
  // redux
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRegisterImage());
  }, [dispatch]);
  const registerImage = useSelector((state) => state.registerImage);

  const validate = () => {
    if (firstName === "") {
      setFirstNameError("First name is required");
      return false;
    } else {
      setFirstNameError("");
    }
    if (lastName === "") {
      setLastNameError("Last name is required");
      return false;
    } else {
      setLastNameError("");
    }
    if (address === "") {
      setAddressError("Address is required");
      return false;
    } else {
      setAddressError("");
    }
    if (companyName === "") {
      setCompanyNameError("Company Name is required");
      return false;
    } else {
      setCompanyNameError("");
    }
    if (phoneNumber === "") {
      setPhoneNumberError("Company Name is required");
      return false;
    } else {
      setPhoneNumberError("");
    }
    if (email === "") {
      setEmailError("Email is required");
      return false;
    } else {
      setEmailError("");
    }
    if (password === "") {
      setPasswordError("Password is required");
      return false;
    } else {
      setPasswordError("");
    }
    if (password.length < 8) {
      setPasswordError("Password should be at least 8 character long.");
      return false;
    } else {
      setPasswordError("");
    }
    if (confirmPassword === "") {
      setConfirmPasswordError("Confirm your password");
      return false;
    } else {
      setConfirmPasswordError("");
    }
    if (password !== confirmPassword) {
      setConfirmPasswordError("Password does not match");
      return false;
    } else {
      setConfirmPasswordError("");
    }
    return true;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (validate()) {
        const data = {
          firstName,
          lastName,
          address,
          companyName,
          phoneNumber,
          email,
          password,
        };
        dispatch(addNewUser(data));
        //   try {
        //     const response = await publicFetch.post(
        //       `${BASE_URL}api/v1/user/register`,
        //       data
        //     );
        //     if (response.data) {
        //       // localStorage.setItem("token", response.data.token);
        //       // dispatch(setToken(response.data.token));
        //       // dispatch(setUser(response.data.result));
        //       dispatch(
        //         setSnackbar({
        //           snackbarOpen: true,
        //           snackbarType: "success",
        //           snackbarMessage: response.data.message,
        //         })
        //       );
        //     }
        //   } catch (error) {
        //     dispatch(
        //       setSnackbar({
        //         snackbarOpen: true,
        //         snackbarType: "error",
        //         snackbarMessage: error.response.data.message,
        //       })
        //     );
        //   }
      }
    } catch (error) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: error.response.data.message,
        })
      );
    }
  };
  return (
    <div className="register-container">
      <div class="session">
        <div
          class="left"
          style={{
            backgroundImage: `url(${BASE_URL}${registerImage?.data[0]?.registerImage.replace(
              "\\",
              "/"
            )})`,
          }}
        />

        <div class="right">
          <div className="signup-form">
            <div className="title">Register</div>
            <div className="sub-title">Create an account here</div>
            <form onSubmit={handleSubmit}>
              <div className="user-details">
                <div className="row">
                  <div className="input-box">
                    <span className="details">First Name</span>
                    <input
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    ></input>
                    <span className="error">{firstNameError}</span>
                  </div>
                  <div className="input-box">
                    <span className="details">Last Name</span>
                    <input
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    ></input>
                    <span className="error">{lastNameError}</span>
                  </div>
                </div>

                <div className="row">
                  <div className="input-box">
                    <span className="details">Address</span>
                    <input
                      type="text"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      required
                    ></input>
                    <span className="error">{addressError}</span>
                  </div>
                  <div className="input-box">
                    <span className="details">Company Name</span>
                    <input
                      type="text"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      required
                    ></input>
                    <span className="error">{companyNameError}</span>
                  </div>
                </div>

                <div className="row">
                  <div className="input-box">
                    <span className="details">Phone Number</span>
                    <input
                      type="text"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      required
                    ></input>
                    <span className="error">{phoneNumberError}</span>
                  </div>
                  <div className="input-box">
                    <span className="details">Email</span>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    ></input>
                    <span className="error">{emailError}</span>
                  </div>
                </div>

                <div className="row">
                  <div className="input-box">
                    <span className="details">Password</span>
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    ></input>
                    <span className="error">{passwordError}</span>
                  </div>
                  <div className="input-box">
                    <span className="details">Confirm Password</span>
                    <input
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    ></input>
                    <span className="error">{confirmPasswordError}</span>
                  </div>
                </div>

                <div>
                  {userState.addStatus === "" ||
                  userState.addStatus === "SUCCESS" ||
                  userState.addStatus === "FAILED" ? (
                    <button className="registerBtn" type="submit">
                      Register
                    </button>
                  ) : (
                    <button type="submit" className="registerBtn" disabled>
                      <CircularProgress className="circular-loader" size={20} />
                    </button>
                  )}
                </div>
                <span id="no-account">
                  Already have an account?{" "}
                  <Link to="/login" className="link">
                    <span>Login </span>
                  </Link>
                </span>
                <div>
                  {userState.addStatus === "SUCCESS" ? (
                    <CustomizedModal />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </form>
          </div>
          <div></div>
        </div>
      </div>
      {/* <div
        className="auth-container__image"
        style={{ backgroundImage: `url(${LoginImage})` }}
      ></div> */}
    </div>
  );
}

export default Register;
