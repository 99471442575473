import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import bgImage from "../../assets/img/bg-image1.jpg";
import { useNavigate } from "react-router-dom";
import Banner from "../../components/banner/Banner";
import "./Category.scss";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import SubHeading from "../../components/heading/SubHeading";
import { getDesignImages } from "../../redux/slices/design.slice";
import BASE_URL from "../../utils/baseUrl";
import { getCategories } from "../../redux/slices/category.slice";
import ConfirmDialog from "../Admin/ConfirmDialog/ConfirmDialog";

const Category = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [dialog, setDialog] = useState({
    isOpen: false,
    _id: "",
  });

  useEffect(() => {
    dispatch(getDesignImages());
    dispatch(getCategories());
  }, [dispatch]);
  const designImage = useSelector((state) => state.design);

  const categories = useSelector((state) => state.category);
  return (
    <div className="cat-container">
      {categories.status === "SUCCESS" ? (
        <>
          <Banner
            title="Rugs' Designs"
            subtitle="Explore rugs' design by their categories"
            image={designImage?.data[0]?.designBanner}
          />
          {user.permissionLevel === 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "12px",
              }}
            >
              <button
                className="btnLogin"
                onClick={() => {
                  navigate("/admin/add-category");
                }}
              >
                Add Category
              </button>
            </div>
          )}

          <div className="cat-body">
            <div className="cat-detail">
              {/* <SubHeading
              title="We got different rugs by category"
              // subtitle="Explore Now"
            /> */}
              <div
                className="card-content"
                // onClick={() => history(`/designs/all`)}
                style={{ cursor: "pointer" }}
              >
                <Link to="/designs/all" style={{ textDecoration: "none" }}>
                  <img
                    src={`${BASE_URL}${designImage?.data[0]?.allCatImage}`}
                  />
                  {/* </div> */}
                  <div className="overlay">
                    <div className="catName">
                      <h3>All</h3>
                    </div>
                  </div>
                </Link>
                {/* <div className="catImage"> */}
              </div>
              {categories.data.map((category) => (
                <article>
                  <div
                    className="card-content"
                    // onClick={() => history(`/designs/${category.slug}`)}
                    style={{ cursor: "pointer" }}
                  >
                    <Link
                      to={`/designs/${category.slug}`}
                      style={{ textDecoration: "none" }}
                    >
                      {/* <div className="catImage"> */}
                      <img src={category.image} />
                      {/* </div> */}
                      <div className="overlay">
                        <div className="catName">
                          <h3>{category.category}</h3>
                        </div>
                      </div>
                    </Link>
                  </div>
                  {user.permissionLevel === 0 && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        marginTop: "10px",
                      }}
                    >
                      <button
                        className="btnLogin"
                        onClick={(e) => {
                          navigate(`/admin/edit-category/${category._id}`);
                        }}
                      >
                        Edit
                      </button>
                      <button
                        className="btnLogin"
                        style={{
                          backgroundColor: "red",
                        }}
                        onClick={() => {
                          setDialog({
                            isOpen: true,
                            _id: category._id,
                          });
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </article>
              ))}
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
      {dialog.isOpen && (
        <ConfirmDialog
          dialog={dialog}
          setDialog={setDialog}
          caller={"/categories"}
        />
      )}
    </div>
  );
};
//123%10

export default Category;
