import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { publicFetch } from "../../utils/fetch";
import { setSnackbar } from "./snackbar.slice";

export const getCategories = createAsyncThunk(
  "category/getCategories",
  async (_, { dispatch, rejectWithValue, getState }) => {
    try {
      // const privateFetch = publicFetch(getState().user.token);
      const response = await publicFetch.get(
        // `/api/v1/categories?page=1&limit=10`
        `/api/v1/categories`
      );
      return response.data;
    } catch (error) {
      const responseMessage = error.response.data.message;
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: responseMessage,
        })
      );
      return rejectWithValue(responseMessage);
    }
  }
);

const categorySlice = createSlice({
  name: "category",
  initialState: {
    data: [],
    totalCount: 0,
    page: 0,
    status: "",
    message: "",
  },
  extraReducers: {
    [getCategories.pending]: (state, action) => {
      state.status = "LOADING";
    },
    [getCategories.fulfilled]: (state, { payload }) => {
      state.data = payload.result;
      state.totalCount = payload.total;
      state.page = payload.page;
      state.status = "SUCCESS";
    },
    [getCategories.rejected]: (state, action) => {
      state.status = "FAILED";
    },
  },
});
export default categorySlice.reducer;
