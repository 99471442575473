import { useState } from "react";
import styles from "./ConfirmDialog.module.css";
import axios from "axios";
import toast from "react-hot-toast";
import BASE_URL from "../../../utils/baseUrl";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
function ConfirmDialog({ dialog, setDialog, caller }) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  console.log(dialog);
  const handleDelete = async () => {
    // let _id = dialog._id;
    try {
      setIsLoading(true);
      const { data } = await axios.delete(
        `${BASE_URL}/api/v1/${caller}/${dialog._id}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      console.log(data);
      toast.success("Successfully deleted.");
      navigate("/", { replace: true });
      setDialog({ ...dialog, isOpen: false, deleted: true });
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      toast.error("Sorry, it could not be deleted");
      setIsLoading(false);
    }
    //
  };

  return (
    <div className={styles.dialog}>
      <div className={styles.container}>
        <h1>Do you want to delete this item?</h1>
        <div>
          <button disabled={isLoading} onClick={handleDelete}>
            Confirm
          </button>
          <button
            disabled={isLoading}
            className={styles.close}
            onClick={() => {
              setDialog({ ...dialog, isOpen: false });
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmDialog;
