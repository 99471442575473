import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";
import { verifyUser } from "../../redux/slices/user.slice";
import { publicFetch } from "../../utils/fetch";
import Loader from "../../components/Loader/Loader";
import { Link, useSearchParams } from "react-router-dom";
import { FaUnlink, FaCheckCircle } from "react-icons/fa";

const Verify = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const users = useSelector((state) => state.user);
  const dispatch = useDispatch();
  console.log(searchParams.get("token"));
  const token = searchParams.get("token");

  useEffect(() => {
    const verifyUser = async () => {
      try {
        const response = await publicFetch.get(
          // `/api/v1/categories?page=1&limit=10`
          `/api/v1/user/verify?token=${token}`
        );
        setData(response.data);
        setLoading(false);
      } catch (error) {
        setData(error.response);
        setLoading(false);
      }
    };
    if (token) {
      (async () => {
        verifyUser();
      })();
    }
    // const result = await dispatch(
    //   verifyUser(params.userID, params.uniqueString)
    // );
    // // setSearchedData(result.payload);
  }, [token]);

  return (
    <div className="verify-container">
      {!loading ? (
        <div>
          {data.success ? (
            <div className="verified">
              <FaCheckCircle size={50} color="#575454" />
              <h1> User has been verified</h1>
              <p>
                Email has been successfully verfied. You can now login to your
                account.
              </p>
              <Link to="/login">
                <button>Continue to Login</button>
              </Link>
            </div>
          ) : (
            <div className="exp-link">
              <FaUnlink size={50} color="#575454" />
              <h1> Link has been expired </h1>
              <p>Oh no! This link has been expired.</p>
              <Link to="/login">
                <button>Continue to Login</button>
              </Link>
            </div>
          )}
        </div>
      ) : (
        <Loader />
      )}
      {/* <p>User has been verified </p> */}
    </div>
  );
};

export default Verify;
