import Axios from "axios";
import BASE_URL from "./baseUrl";
import Cookies from "js-cookie";

export const publicFetch = Axios.create({ baseURL: BASE_URL });

export const privateFetch = Axios.create({
  baseURL: BASE_URL,
  headers: {
    // authorization: "Bearer " + localStorage.getItem("token"),
    authorization: "Bearer " + Cookies.get("token"),
  },
});

export const getPrivateFetch = (token) => {
  return Axios.create({
    baseURL: BASE_URL,
    headers: {
      authorization: "Bearer " + token,
    },
  });
};
