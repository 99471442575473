import { useEffect, useState } from "react";
// import "./Auth.scss";
import "./login.scss";
import LoginImage from "../../assets/img/login.jpeg";
import { publicFetch } from "../../utils/fetch";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../../redux/slices/user.slice";
import BASE_URL from "../../utils/baseUrl";
import { setSnackbar } from "../../redux/slices/snackbar.slice";
import Cookies from "js-cookie";
import Loader from "../../components/Loader/Loader";
import { CircularProgress } from "@material-ui/core";
import { getLoginImage } from "../../redux/slices/loginImage.slice";

function Login() {
  const history = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  // redux
  const dispatch = useDispatch();

  //
  useEffect(() => {
    dispatch(getLoginImage());
  }, [dispatch]);

  const validate = () => {
    if (email === "") {
      setEmailError("Email is Required");
      setLoading(false);
      return false;
    } else {
      setEmailError("");
    }
    if (password === "") {
      setPasswordError("Password is Required");
      setLoading(false);

      return false;
    } else {
      setPasswordError("");
    }
    return true;
  };
  const loginImage = useSelector((state) => state.loginImage);
  const [image, setImage] = useState("");

  // const snackbarState = useSelector((state) => state.snackbar);
  // console.log(snackbarState);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (validate()) {
      const data = { email, password };
      console.log(data);
      try {
        const response = await publicFetch.post(
          `${BASE_URL}/api/v1/user/login`,
          data
        );
        if (response.data) {
          Cookies.set("token", response.data.token);
          // Cookies.set("user", response.data.result);
          dispatch(setToken(response.data.token));
          dispatch(setUser(response.data.result));

          // localStorage.setItem("token", response.data.token);
          // dispatch(setToken(response.data.token));
          // dispatch(setUser(response.data.result));
          setLoading(false);
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: "success",
              snackbarMessage: response.data.message,
            })
          );
          history("/home");
        }
      } catch (error) {
        // console.log(error.response.data.message);
        setLoading(true);
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage:
              error?.response?.data?.message || "Some error occured",
          })
        );
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };
  return (
    <div className="login-container">
      <div class="session">
        <div
          class="left"
          style={{
            backgroundImage: `url(${BASE_URL}${loginImage?.data[0]?.loginImage.replace(
              "\\",
              "/"
            )})`,
          }}
        >
          {/* <img src={LoginImage} style={{ height: "fitContent" }} /> */}
        </div>
        <div className="login-form">
          <div className="title">Login</div>

          <div className="sub-title">Login to your account.</div>
          <form action="" class="log-in" onSubmit={handleSubmit}>
            <div className="input-field">
              <label for="email">Email</label>
              <input
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <span className="error">{emailError}</span>
            </div>

            <div className="input-field">
              <label for="password">Password</label>
              <input
                type="password"
                name="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span className="error">{passwordError}</span>
            </div>
            <span id="accept-policy">
              By login, you will accept our{" "}
              <Link to="/terms-and-conditions" className="link">
                <span>Terms And Contitions</span>
              </Link>{" "}
              and{" "}
              <Link to="/privacy-policy" className="link">
                <span>Privacy Policy</span>
              </Link>
              .
            </span>
            <a href="/forget-password" class="discrete" target="_blank">
              Forget Password
            </a>
            {loading ? (
              <button className="loadingBtn" disabled>
                <CircularProgress size={18} color={"#fff"} />
                Log in
              </button>
            ) : (
              <button className="loginBtn" type="submit">
                Log in
              </button>
            )}

            <span id="no-account">
              Not Registered Yet?{" "}
              <Link to="/register" className="link">
                <span>Create an account </span>
              </Link>
            </span>
          </form>
        </div>
      </div>
      {/* <div
        className="auth-container__image"
        style={{ backgroundImage: `url(${LoginImage})` }}
      ></div> */}
    </div>
  );
}

export default Login;
