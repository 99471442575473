import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { publicFetch } from "../../utils/fetch";
import { setSnackbar } from "../../redux/slices/snackbar.slice";
import Cookies from "js-cookie";

export const addNewUser = createAsyncThunk(
  "user/addNewUser",
  async (newUser, { rejectWithValue, dispatch, getState }) => {
    try {
      const userResponse = await publicFetch.post("/api/v1/user/register", {
        firstName: newUser.firstName,
        lastName: newUser.lastName,
        address: newUser.address,
        companyName: newUser.companyName,
        phone: newUser.phoneNumber,
        email: newUser.email,
        password: newUser.password,
      });
      const data = userResponse.data;
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: data.message,
        })
      );
      return data;
    } catch (error) {
      const responseMessage = error.response.data.message;
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: responseMessage,
        })
      );
      return rejectWithValue(responseMessage);
    }
  }
);

export const verifyUser = createAsyncThunk(
  "user/verifyuser",
  async (userId, secKey, { dispatch, rejectWithValue, getState }) => {
    try {
      const response = await publicFetch.get(
        // `/api/v1/categories?page=1&limit=10`
        `/api/v1/user/verify/${userId}/${secKey}`
      );
      return response.data;
    } catch (error) {
      return error.response.data;
      // return rejectWithValue(responseMessage);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    token: Cookies.get("token") || "",
    user: JSON.parse(Cookies.get("user") || "{}") || {},
    // token: localStorage.getItem("token") || "",
    // user: JSON.parse(localStorage.getItem("user")) || {},
    status: "",
    addStatus: "",
    verifiedData: [],
    verifyStatus: "",
    message: "",
    data: [],
  },
  reducers: {
    setToken: (state, action) => {
      return { ...state, token: action.payload };
    },
    removeToken: (state, action) => {
      // localStorage.removeItem("token");
      localStorage.removeItem("token");
      return { user: {}, token: "" };
    },
    setUser: (state, action) => {
      // localStorage.setItem("user", JSON.stringify(action.payload));
      console.log("action", action.payload);
      Cookies.set("user", JSON.stringify(action.payload));
      return { ...state, user: action.payload };
    },
  },
  extraReducers: {
    [addNewUser.pending]: (state, action) => {
      state.addStatus = "LOADING";
    },
    [addNewUser.fulfilled]: (state, { payload }) => {
      state.data = [...state.data, payload.result];
      state.totalCount += 1;
      state.addStatus = "SUCCESS";
    },
    [addNewUser.rejected]: (state, action) => {
      state.addStatus = "FAILED";
    },
    [verifyUser.pending]: (state, action) => {
      state.verifyStatus = "LOADING";
    },
    [verifyUser.fulfilled]: (state, { payload }) => {
      state.verifiedData = [...state.data, payload.result];
      state.verifyStatus = "SUCCESS";
    },
    [verifyUser.rejected]: (state, action) => {
      state.verifyStatus = "FAILED";
    },
  },
});

export const { setToken, removeToken, setUser } = userSlice.actions;

export default userSlice.reducer;
