import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getPrivateFetch } from "../../utils/fetch";
import { setSnackbar } from "./snackbar.slice";

// export const
export const getFav = createAsyncThunk(
  "fav/getfav",
  async (_, { dispatch, rejectWithValue, getState }) => {
    try {
      const privateFetch = getPrivateFetch(getState().user.token);
      const response = await privateFetch.get(`/api/v1/fav`);
      return response.data;
    } catch (error) {
      const responseMessage = error.response.data.message;
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: responseMessage,
        })
      );
      return rejectWithValue(responseMessage);
    }
  }
);
export const addFav = createAsyncThunk(
  "fav/addfav",
  async (rugID, { rejectWithValue, dispatch, getState }) => {
    try {
      const privateFetch = getPrivateFetch(getState().user.token);

      const favResponse = await privateFetch.post("/api/v1/fav", {
        rugID,
      });
      const data = favResponse.data;
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: data.message,
        })
      );
      return data;
    } catch (error) {
      const responseMessage = error.response.data.message;
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: responseMessage,
        })
      );
      return rejectWithValue(responseMessage);
    }
  }
);

const favoriteSlice = createSlice({
  name: "favorite",
  initialState: {
    data: [],
    status: "",
    message: "",
    totalCount: 0,
    addStatus: "",
  },
  extraReducers: {
    [getFav.pending]: (state, action) => {
      state.status = "LOADING";
    },
    [getFav.fulfilled]: (state, { payload }) => {
      state.data = payload.result;
      //   state.totalCount = payload.total;
      //   state.page = payload.page;
      state.status = "SUCCESS";
    },
    [getFav.rejected]: (state, action) => {
      state.status = "FAILED";
    },
    [addFav.pending]: (state, action) => {
      state.addStatus = "LOADING";
    },
    [addFav.fulfilled]: (state, { payload }) => {
      state.data = [...state.data, payload.result];
      state.totalCount += 1;
      state.addStatus = "SUCCESS";
    },
    [addFav.rejected]: (state, action) => {
      state.addStatus = "FAILED";
    },
  },
});
export default favoriteSlice.reducer;
