import React, { useEffect } from "react";
import landing1 from "../../assets/img/landing-1.png";
import landing2 from "../../assets/img/landing-2.png";
import "./Home.scss";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BASE_URL from "../../utils/baseUrl";

import { Swiper, SwiperSlide } from "swiper/react";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import Title from "../../components/heading/Title";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import AwesomeSlider from "react-awesome-slider";
// import AwsSliderStyles from "react-awesome-slider/src/styles.scss";
import "react-awesome-slider/dist/styles.css";
// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/components/pagination";
import { getCategories } from "../../redux/slices/category.slice";
import { getLatestRugs } from "../../redux/slices/rugs.slice";
// // import Swiper core and required modules
import SwiperCore, { Navigation, Autoplay, Pagination } from "swiper";
import { getHomeImage } from "../../redux/slices/home.slice";

// // install Swiper modules
SwiperCore.use([Navigation, Autoplay, Pagination]);

// import "./styles.css";

const Home = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHomeImage());
    dispatch(getCategories());
    dispatch(getLatestRugs());
  }, [dispatch]);

  const categories = useSelector((state) => state.category);
  const rugs = useSelector((state) => state.rugs);
  const home = useSelector((state) => state.home);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="home-container">
      <div className="top-section">
        <Swiper
          autoplay={{
            delay: 3500,
            disableOnInteraction: true,
            reverseDirection: false,
          }}
          loop={true}
          pagination={{
            // dynamicBullets: false,
            clickable: true,
          }}
          navigation={true}
          modules={([Navigation], [Pagination])}
          className="mySwiper"
        >
          {home.data.map((img, i) => (
            <SwiperSlide>
              <div className="top-container">
                <img src={i % 2 === 0 ? landing1 : landing2} loading="lazy" />

                <div className="heading">
                  <h1 className="top-title">Welcome</h1>
                  <p className="top-subtitle">
                    Greetings from Novo Creation to our best hand made custom
                    area rugs. Here you will see the most creative contemporary
                    area rug designs in the earth. We share & produce most
                    beautiful, creative & finest quality rugs in the world.
                  </p>
                  <Link to="/designs/all">
                    <button className="viewAll">View All Designs</button>
                  </Link>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="category-section">
        <Title
          title="Design Collections"
          subtitle="The Most Creative Rug Designs"
        />
        {categories.status === "SUCCESS" ? (
          <div className="cat-detail">
            <button id="prev">
              <BsChevronLeft size={28} color="#A3A3A3" />
            </button>
            <button id="next">
              <BsChevronRight size={28} color="#A3A3A3" />
            </button>
            <Swiper
              navigation={{
                nextEl: "#next",
                prevEl: "#prev",
              }}
              slidesPerView={3}
              autoplay={{
                delay: 3500,
                disableOnInteraction: false,
                reverseDirection: false,
              }}
              spaceBetween={30}
              loop={true}
              centeredSlides={true}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                550: {
                  slidesPerView: 2,
                },
                883: {
                  slidesPerView: 3,
                },
                1580: {
                  slidesPerView: 4,
                },
              }}
              className="mySwiper"
            >
              {categories.data.map((category) => (
                <SwiperSlide>
                  <div
                    className="swiper-content"
                    onClick={() => history(`/designs/${category.slug}`)}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={category.image} loading="lazy" />
                    <div className="catName">
                      <h3>{category.category}</h3>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        ) : (
          <Loader />
        )}
      </div>
      <div className="rugs-section">
        <Title
          title="Latest Designs"
          subtitle="Checkout Newly Introduced Designs"
        />
        {rugs.getStatus === "SUCCESS" ? (
          <div className="home-rug-details">
            <button id="prevRug">
              <BsChevronLeft size={28} color="#929292" />
            </button>
            <button id="nextRug">
              <BsChevronRight size={28} color="#929292" />
            </button>
            <Swiper
              navigation={{
                nextEl: "#nextRug",
                prevEl: "#prevRug",
              }}
              slidesPerView={4}
              autoplay={{
                delay: 3500,
                disableOnInteraction: false,
                reverseDirection: false,
              }}
              spaceBetween={10}
              centeredSlides={true}
              loop={true}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                650: {
                  slidesPerView: 2,
                },
                883: {
                  slidesPerView: 3,
                },
                1250: {
                  slidesPerView: 4,
                },
                1650: {
                  slidesPerView: 5,
                },
              }}
            >
              {rugs.latestData.map((rug, index) => (
                <SwiperSlide>
                  <div
                    className="home-card"
                    onClick={() => history(`/rug/${rug.slug}`)}
                  >
                    <img
                      src={rug.designs[0].image}
                      onMouseEnter={(e) =>
                        rug?.designs[1]
                          ? (e.currentTarget.src = rug?.designs[1]?.image)
                          : (e.currentTarget.src = rug?.designs[0]?.image)
                      }
                      onMouseOut={(e) =>
                        (e.currentTarget.src = rug?.designs[0]?.image)
                      }
                      loading="lazy"
                    />
                    <div className="home-rugName">
                      <h3>{rug.rugName}</h3>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default Home;
