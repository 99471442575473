import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";
import { publicFetch } from "../../utils/fetch";
import Loader from "../../components/Loader/Loader";
import { Link } from "react-router-dom";
import { FaUnlink, FaCheckCircle } from "react-icons/fa";
import { CircularProgress } from "@material-ui/core";
import { setSnackbar } from "../../redux/slices/snackbar.slice";

import "./ChangePassword.scss";

const ChangePassword = () => {
  const dispatch = useDispatch();

  const params = useParams();
  const [data, setData] = useState([]);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const validate = () => {
    if (password.length < 8) {
      setPasswordError("Password should be minimum of 8 character");
      return false;
    } else {
      setPasswordError("");
    }
    if (password === "") {
      setPasswordError("Password is Required");
      return false;
    } else {
      setPasswordError("");
    }
    if (confirmPassword === "") {
      setConfirmPassword("Confirm your password");

      return false;
    } else {
      setPasswordError("");
    }
    if (password !== confirmPassword) {
      setConfirmPasswordError("Password does not match");
      return false;
    } else {
      setConfirmPasswordError("");
    }
    return true;
  };

  const changePassword = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        setLoading(true);
        const response = await publicFetch.get(
          // `/api/v1/categories?page=1&limit=10`
          `/api/v1/reset-password/${params.userID}/${params.uniqueString}`
        );
        setData(response.data);
        setLoading(false);
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: response.data.message,
          })
        );
      } catch (error) {
        setLoading(true);
        setData(error.response.data);
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: error.response.data.message,
          })
        );
        setLoading(false);
      }
    }
  };
  return (
    <div className="change-password-container">
      {data.length <= 1 ? (
        <form className="change-password" onSubmit={changePassword}>
          <h2>Reset Password</h2>
          <div>
            <input
              type="password"
              name="password"
              id="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <span className="error">{passwordError}</span>
          </div>
          <div>
            <input
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              placeholder="Retype Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <span className="error">{confirmPasswordError}</span>
          </div>
          <div>
            {!loading ? (
              <button className="changePwdBtn" type="submit">
                Change Password
              </button>
            ) : (
              <button type="submit" className="changePwdBtn" disabled>
                <CircularProgress className="circular-loader" size={20} />
              </button>
            )}
          </div>
        </form>
      ) : (
        <div className="verify-container">
          {data.success ? (
            <div className="verified">
              <FaCheckCircle size={50} color="#575454" />
              <h1> User has been verified</h1>
              <p>
                Email has been successfully verfied. You can now login to your
                account.
              </p>
              <Link to="/login">
                <button>Go to Home</button>
              </Link>
            </div>
          ) : (
            <div className="exp-link">
              <FaUnlink size={50} color="#575454" />
              <h1> Link has been expired </h1>
              <p>Oh no! This link has been expired.</p>
              <Link to="/login">
                <button>Go to Home</button>
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ChangePassword;
