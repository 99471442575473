import React, { useState, useEffect } from "react";
import "./Designs.scss";
import Banner from "../../components/banner/Banner";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Tabs as TabsUnstyled } from "@mui/base/Tabs";
import { TabsList as TabsListUnstyled } from "@mui/base/TabsList";
import { Tab as TabUnstyled } from "@mui/base/Tab";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "react-tabs/style/react-tabs.css";
import { GrNext, GrPrevious } from "react-icons/gr";
import Loader from "../../components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import SubHeading from "../../components/heading/SubHeading";
import { getDesignImages } from "../../redux/slices/design.slice";
import "../../components/topbar.scss";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";

// // import Swiper core and required modules
import SwiperCore, { Navigation, Autoplay } from "swiper";

// // install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

export const Designs = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [categoryType, setCategoryType] = useState("");
  const params = useParams();
  useEffect(() => {
    setCategoryType(params.slug);
    dispatch(getDesignImages());
  }, [params, dispatch]);
  const designImage = useSelector((state) => state.designImage);
  const categories = useSelector((state) => state.category);
  const rugs = useSelector((state) => state.rugs);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  // let n = 0;
  return (
    <div className="design-container">
      <Banner
        title="Rugs' Designs"
        subtitle="Find various designed rugs for your home or office"
      />
      <div className="design-body">
        {/* <div className="design-heading">
          <p>
            <span className="subtitle">Explore</span>
          </p>
          <h2 className="title">Our Rug Designs</h2>
        </div> */}
        <button className="btnLogin" onClick={() => {}}>
          Add Category
        </button>
        <SubHeading title="Our Rug Designs" subtitle="Explore" />
        <div className="design-categories">
          {rugs.getStatus === "SUCCESS" && categories.status === "SUCCESS" ? (
            <TabsUnstyled defaultValue={1} style={{ width: "100%" }}>
              <TabsListUnstyled className="cat-list">
                {/* <button id="prev">
                <GrPrevious size={18} color="#A3A3A3" />
              </button>
              <button id="next">
                <GrNext size={18} color="#A3A3A3" />
              </button> */}
                <Swiper
                  navigation={{
                    nextEl: "#next",
                    prevEl: "#prev",
                  }}
                  slidesPerView={6}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    370: {
                      slidesPerView: 2,
                    },
                    500: {
                      slidesPerView: 2,
                    },
                    768: {
                      slidesPerView: 3,
                    },
                    950: {
                      slidesPerView: 4,
                    },
                    1137: {
                      slidesPerView: 5,
                    },
                    1337: {
                      slidesPerView: 7,
                    },
                    1558: {
                      slidesPerView: 8,
                    },
                  }}
                  // autoplay={{
                  //   delay: 3000,
                  //   disableOnInteraction: false,
                  //   reverseDirection: false,
                  // }}
                  loop={false}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <TabUnstyled
                      className={
                        categoryType === "all"
                          ? "tabbed-category active"
                          : "tabbed-category"
                      }
                      // onClick={() => setCategoryType("All")}
                      onClick={() => history(`/designs/all`)}
                    >
                      All
                    </TabUnstyled>
                  </SwiperSlide>
                  {categories.data.map((category) => (
                    <SwiperSlide>
                      <TabUnstyled
                        className={
                          categoryType === category.slug
                            ? "tabbed-category active"
                            : "tabbed-category"
                        }
                        // eventKey={`/${category.category}`}
                        // onClick={() => setCategoryType(category.category)}
                        onClick={() => history(`/designs/${category.slug}`)}
                      >
                        {category.category}
                      </TabUnstyled>
                    </SwiperSlide>
                  ))}
                </Swiper>
                {/* <TabUnstyled className="tabbed-category">Three</TabUnstyled> */}
              </TabsListUnstyled>

              <div className="rug-improvised-detail">
                {rugs.data
                  .filter((rug) => {
                    return categoryType === "all"
                      ? true
                      : rug.category.slug === categoryType;
                  })
                  .map((rug) => (
                    <Link to={`/rug/${rug.slug}`}>
                      <div className="card">
                        <img
                          src={rug.designs[0].image}
                          onMouseOver={(e) =>
                            rug?.designs[1]
                              ? (e.currentTarget.src = rug?.designs[1]?.image)
                              : (e.currentTarget.src = rug?.designs[0]?.image)
                          }
                          onMouseOut={(e) =>
                            (e.currentTarget.src = rug?.designs[0]?.image)
                          }
                        />
                        <div className="rugName">
                          <h3>{rug.rugName}</h3>
                        </div>
                      </div>
                    </Link>
                  ))}
              </div>

              {/* </TabPanelUnstyled> */}
              {/* );
            })} */}
              {/* <TabPanelUnstyled value={2}>Third content</TabPanelUnstyled> */}
            </TabsUnstyled>
          ) : (
            <Loader />
          )}

          {/* <button className="catButton active">All</button>
          {categories.data.map((category) => (
            <button className="catButton">{category.category}</button>
          ))} */}
        </div>
      </div>
    </div>
  );
};
