import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { publicFetch } from "../../utils/fetch";
import { setSnackbar } from "./snackbar.slice";

export const postMail = createAsyncThunk(
  "mail/sendMail",
  async (values, { dispatch, rejectWithValue, getState }) => {
    try {
      // const privateFetch = publicFetch(getState().user.token);
      const response = await publicFetch.post(
        // `/api/v1/categories?page=1&limit=10`
        `/api/v1/mail`,
        {
          fullName: values.fullName,
          email: values.email,
          phone: values.phone,
          companyName: values.companyName,
          message: values.message,
        }
      );
      const data = response.data;
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: data.message,
        })
      );
      return data;
    } catch (error) {
      const responseMessage = error.response.data.message;
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: responseMessage,
        })
      );
      return rejectWithValue(responseMessage);
    }
  }
);

const mailSlice = createSlice({
  name: "mail",
  initialState: {
    data: [],
    totalCount: 0,
    page: 0,
    addStatus: "",
    message: "",
  },
  extraReducers: {
    [postMail.pending]: (state, action) => {
      state.addStatus = "LOADING";
    },
    [postMail.fulfilled]: (state, { payload }) => {
      // state.data = [...state.data, payload.result];
      state.addStatus = "SUCCESS";
    },
    [postMail.rejected]: (state, action) => {
      state.addStatus = "FAILED";
    },
  },
});
export default mailSlice.reducer;
