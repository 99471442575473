import React, { useContext } from "react";
import styles from "./Cart.module.css";
import { useCartContext } from "../../store/CartContext";
import { FaMinus, FaPlus } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";

const Cart = () => {
  const { cart, itemDecreaseHandler, itemAddHandler, cartRemoveHandler } =
    useCartContext();
  console.log(cart);
  return (
    <main className={styles.container}>
      <h1 className={styles.heading}>My Cart</h1>
      <article className={styles.main}>
        {cart?.items.length === 0 && (
          <div className={styles.warning}> No items to display</div>
        )}
        {cart?.items.length !== 0 && (
          <section className={styles["table-container"]}>
            <table>
              <thead>
                <tr>
                  <th className={styles["wider-cell"]}>Items</th>
                  <th className={styles.center}>Unit Price</th>
                  <th className={styles.center}>Quantity</th>
                  <th className={styles.center}>Total Price</th>
                </tr>
              </thead>
              <tbody>
                {cart?.items.map(
                  ({ image, name, size, quantity, unitPrice, _id }, i) => (
                    <tr key={i}>
                      <td className={styles["wider-cell"]}>
                        <section className={styles.img}>
                          <img src={image} alt="laddu" />
                          <RxCross2
                            onClick={() => {
                              cartRemoveHandler({ _id });
                            }}
                          />
                        </section>
                        <div className={styles["name-container"]}>
                          <span>{name}</span>
                          <span className={styles.size}>{size}</span>
                        </div>
                      </td>
                      <td className={styles.center}>£ {unitPrice}</td>
                      <td className={styles.center}>
                        <div>
                          <FaMinus
                            onClick={itemDecreaseHandler.bind(null, {
                              _id,
                              size,
                            })}
                          />
                          {quantity}
                          <FaPlus
                            onClick={() => {
                              itemAddHandler({ _id, size });
                            }}
                          />
                        </div>
                      </td>
                      <td className={styles.center}>£{unitPrice * quantity}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </section>
        )}
        {cart && cart?.total !== 0 && (
          <section className={styles.payments}>
            <h1 className={styles.summary}>Summary</h1>
            <div className={styles["details-container"]}>
              <div className={styles.details}>
                <span>Sub Total:</span>
                <span className={styles.price}>£ {cart.total}</span>
              </div>
              <div className={styles.details}>
                <span>Tax:</span>
                <span className={styles.price}>£ 10</span>
              </div>
              <div className={styles.details}>
                <span>Delivery:</span>
                <span className={styles.price}>£ 100</span>
              </div>
            </div>
            <div className={styles.total}>
              <span>Total:</span>
              <span className={styles.price}>£ {cart.total + 100 + 10}</span>
            </div>
            <button className={styles.button}>Check Out</button>
          </section>
        )}
      </article>
    </main>
  );
};

export default Cart;
